export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILEE = "FETCH_PROFILEE";
export const FETCH_VISITS = "FETCH_VISITS";
export const SET_VISITS_DATA = "SET_VISITS_DATA";
export const CLICK_SAVE_VISIT = "CLICK_SAVE_VISIT";
export const CLICK_ADD_VISIT = "CLICK_ADD_VISIT";
export const CLICK_ACCORDION_FILTER_VISIT = "CLICK_ACCORDION_FILTER_VISIT";
export const CLICK_VIEW_HISTORY = "CLICK_VIEW_HISTORY";
export const CLOSE_ACCORDION_FILTER_VISIT = "CLOSE_ACCORDION_FILTER_VISIT";
export const CLICK_CANCEL_VISIT = "CLICK_CANCEL_VISIT";
export const FETCH_FILTERS = "FETCH_FILTERS";
export const UPDATE_APPLIED_FILTERS = "UPDATE_APPLIED_FILTERS";
export const VISITS_FILTER = "VISITS_FILTER";
export const FETCH_TAIL_NUMBER_LOOKUP = "FETCH_TAIL_NUMBER_LOOKUP";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
export const FETCH_VISIT_INFO = "FETCH_VISIT_INFO";
export const FETCH_VISIT_INFO_LITE = "FETCH_VISIT_INFO_LITE";
export const UPDATE_VISIT = "UPDATE_VISIT";
export const UPDATE_VISIT_INFO_LOCALLY = "UPDATE_VISIT_INFO_LOCALLY";
export const SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE";
export const RESET_NOTIFICATION_MESSAGE = "RESET_NOTIFICATION_MESSAGE";
export const RESET_UPDATE_FLAGS = "RESET_UPDATE_FLAGS";
export const RTS_AIRCRAFT = "RTS_AIRCRAFT";
export const RESET_RTS_FLAGS = "RESET_RTS_FLAGS";
export const UPDATE_ETR_INFO_LOCALLY = "UPDATE_ETR_INFO_LOCALLY";
export const CLOSE_VISIT = "CLOSE_VISIT";
export const FETCH_EXTENDED_PROFILE = "FETCH_EXTENDED_PROFILE";
export const FETCH_RELEASE_NOTE = "FETCH_RELEASE_NOTE";
export const FETCH_RELEASE_VERSION = "FETCH_RELEASE_VERSION";
export const SET_CURRENT_PATH = "SET_CURRENT_PATH";
export const SET_LAST_REFRESHED = "SET_LAST_REFRESHED";
export const FETCH_REGISTER_DATA = "FETCH_REGISTER_DATA";
export const HANDLE_DASH8_SELECTION = "HANDLE_DASH8_SELECTION";
export const UPDATE_DASH1_SELECTION = "UPDATE_DASH1_SELECTION";
export const SET_SEARCH_CARDS_TEXT = "SET_SEARCH_CARDS_TEXT";
export const FETCH_TASKCARD_DATA = 'FETCH_TASKCARD_DATA';
export const UPDATE_SIGNOFF_ARRAY = 'UPDATE_SIGNOFF_ARRAY';
export const UPDATE_SIGNOFF_VALIDATIONS = "UPDATE_SIGNOFF_VALIDATIONS";
export const UPDATE_SIGNOFF_ARRAY_SUMBIT = "UPDATE_SIGNOFF_ARRAY_SUMBIT ";
export const SET_GENERIC_ERROR = "SET_GENERIC_ERROR";
export const RESET_GENERIC_ERROR = "RESET_GENERIC_ERROR";
export const SIGNOFF_BUTTON_CLICK = "SIGNOFF_BUTTON_CLICK";
export const RESET_TASKCARD_DATA = "RESET_TASKCARD_DATA";
export const ADD_NAV_POINTER = "ADD_NAV_POINTER";
export const SET_ACTIVE_NAV_ITEM = "SET_ACTIVE_NAV_ITEM";
export const RESET_SIGNOFF_ERROR_MESSAGE = "RESET_SIGNOFF_ERROR_MESSAGE";
export const TOGGLE_SIGNOFF_MODAL = "TOGGLE_SIGNOFF_MODAL";
export const FETCH_ILLUSTRATIONS_IMAGES = "FETCH_ILLUSTRATIONS_IMAGES";
export const RESET_ILLUSTRATIONS_IMAGES = "RESET_ILLUSTRATIONS_IMAGES";
export const FETCH_ARCHIVE_SEARCH_RESULTS = "FETCH_ARCHIVE_SEARCH_RESULTS";
export const HANDLE_SORT_ARCHIVE = "HANDLE_SORT_ARCHIVE";
export const STORE_ARCHIVE_SEARCH_PARAMS = "STORE_ARCHIVE_SEARCH_PARAMS";
export const IS_ARCHIVE = "IS_ARCHIVE";
export const IS_MASTER = "IS_MASTER";
export const FETCH_MASTERCARD_DATA = "FETCH_MASTERCARD_DATA";
export const RESET_WARNING_MESSAGE = "RESET_WARNING_MESSAGE";
export const CARD_SEARCH_TYPE = "CARD_SEARCH_TYPE";
export const RESET_SORT = "RESET_SORT";
export const TOGGLE_BULK_SIGNOFF_MODAL = "TOGGLE_BULK_SIGNOFF_MODAL";
export const RESET_SIGNOFF_ARRAY = "RESET_SIGNOFF_ARRAY";
export const MARK_PAPERDASH1_STATUS = " MARK_PAPERDASH1_STATUS";
export const RESET_PAPERDASH1_ERROR_MESSAGE = "RESET_PAPERDASH1_ERROR_MESSAGE";
export const SIGNOFF_PAPERDASH8 = "SIGNOFF_PAPERDASH8";
export const TOGGLE_PAPER_SIGNOFF_MODAL = "TOGGLE_PAPER_SIGNOFF_MODAL";
export const RESET_PAPERDASH8_ERRORS = "RESET_PAPERDASH8_ERRORS";
export const SET_VIEWING_STATION = "SET_VIEWING_STATION";
export const FETCH_NONROUTINES = "FETCH_NONROUTINES";
export const FETCH_NONROUTINE_DETAILS = "FETCH_NONROUTINE_DETAILS";
export const UPDATE_NONROUTINE = "UPDATE_NONROUTINE";
export const NONROUTINE_OPEN_FILTERS = 'NONROUTINE_OPEN_FILTERS';
export const SET_SEARCH_CARDS_TEXT_NR = "SET_SEARCH_CARDS_TEXT_NR";
export const CREATE_NONROUTINE = "CREATE_NONROUTINE";
export const FETCH_NONROUTINES_REF_DATA = "FETCH_NONROUTINES_REF_DATA";
export const RESET_CREATE_NONROUTINE_FLAGS = "RESET_CREATE_NONROUTINE_FLAGS";
export const SET_NONROUTINE_ACTIONS_VARIABLE = "SET_NONROUTINE_ACTIONS_VARIABLE";
export const RESET_NONROUTINE_ACTIONS_VARIABLE = "RESET_NONROUTINE_ACTIONS_VARIABLE";
export const ADD_MXNOTES_NONROUTINES = "ADD_MXNOTES_NONROUTINES";
export const RESET_NONROUTINE_REGISTER_DATA = "RESET_NONROUTINE_REGISTER_DATA";
export const SUBTASK_SIGNOFF = "SUBTASK_SIGNOFF";
export const FETCH_NONROUTINE_LOGPAGES = "FETCH_NONROUTINE_LOGPAGES";
export const TOGGLE_SUBTASK_SIGNOFF_MODAL = "TOGGLE_SUBTASK_SIGNOFF_MODAL";
export const FETCH_NONROUTINE_IMAGES = "FETCH_NONROUTINE_IMAGES";
export const UPDATE_SUBTASK_SORT_ORDER = "UPDATE_SUBTASK_SORT_ORDER";
export const RESET_VIEW_NR_ERROR_MESSAGE = "RESET_VIEW_NR_ERROR_MESSAGE";
export const TOGGLE_ADD_SUBTASK_MODAL = "TOGGLE_ADD_SUBTASK_MODAL";
export const FETCH_NONROUTNE_HISTORY = "FETCH_NONROUTNE_HISTORY";
export const NONROUTINE_HISTORY_FILTERS = "NONROUTINE_HISTORY_FILTERS";
export const HANDLE_NONROUTINE_ACTIONS = "HANDLE_NONROUTINE_ACTIONS";
export const FETCH_WORKLOAD_ACCOUNTABILITY_DATA = "FETCH_WORKLOAD_ACCOUNTABILITY_DATA";
export const ACCOUNTABILITY_FILTERS = "ACCOUNTABILITY_FILTERS";
export const RESET_ACCOUNTABILITY_DATA = "RESET_ACCOUNTABILITY_DATA";
export const IS_APPLY_FILTERS_CLICK_ACTION = "IS_APPLY_FILTERS_CLICK_ACTION";
export const SET_AIRCRAFT_INFO = "SET_AIRCRAFT_INFO";
export const RESET_ROUTINE_REGISTER = "RESET_ROUTINE_REGISTER";
export const RESET_NR_FILTERS = "RESET_NR_FILTERS";
export const GET_MRD_STATUS = "GET_MRD_STATUS";
export const CHECK_MRD = "CHECK_MRD";
export const SET_SHOW_CHECK_MRD_MODAL = "SET_SHOW_CHECK_MRD_MODAL";
export const SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL = "SET_SHOW_ISSUE_MRD_CONFIRMATION_MODAL";
export const SET_SHOW_ISSUE_MRD_ERROR_MODAL = "SET_SHOW_ISSUE_MRD_ERROR_MODAL";
export const ISSUE_MRD = "ISSUE_MRD";
export const GET_STATION_LOCAL_TIME = "GET_STATION_LOCAL_TIME";
export const RESET_MRD_ERROR_MESSAGES = "RESET_MRD_ERROR_MESSAGES";
export const SET_SHOW_VIEW_MRD_MODAL = "SET_SHOW_VIEW_MRD_MODAL";
export const VIEW_MRD = "VIEW_MRD";
export const FETCH_CARRIED_DEFFERED_LOGPAGE_DATA = "FETCH_CARRIED_DEFFERED_LOGPAGE_DATA";
export const FETCH_INDUCTED_AIRCRAFT_INFO = "FETCH_INDUCTED_AIRCRAFT_INFO";
export const RESET_INDUCTED_AIRCRAFT_INFO = "RESET_INDUCTED_AIRCRAFT_INFO";
export const VIEW_MFD = " VIEW_MFD";
export const FETCH_VISIT_SCHEDULE_SHIFT_INFO = "FETCH_VISIT_SCHEDULE_SHIFT_INFO";
export const FETCH_LOAD_GROUPS = "FETCH_LOAD_GROUPS";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const SAVE_VISIT_SCHEDULE = "SAVE_VISIT_SCHEDULE";
export const RESET_LOADGROUPS = "RESET_LOADGROUPS";
export const RESET_VISIT_INFO = "RESET_VISIT_INFO";
export const FETCH_LINKED_LOGPAGES = "FETCH_LINKED_LOGPAGES";
export const FETCH_PARTS_AND_TOOLS_DATA = "FETCH_PARTS_AND_TOOLS_DATA";
export const PARTS_AND_TOOLS_FILTERS = "PARTS_AND_TOOLS_FILTERS";
export const RESET_LINKED_LOGPAGE_DATA = "RESET_LINKED_LOGPAGE_DATA";
export const SET_SHOW_PARTS_AND_TOOLS_SUMMARY_LINK = "SET_SHOW_PARTS_AND_TOOLS_SUMMARY_LINK";
export const SET_GROUP_SCHEDULE_UPDATE_FLAG = "SET_GROUP_SCHEDULE_UPDATE_FLAG";
export const SET_SAVE_MXGROUP_REQUEST = "SET_SAVE_MXGROUP_REQUEST";
export const FETCH_GROUPS_HISTORY = "FETCH_GROUPS_HISTORY";
export const SET_INFO_MODAL = "SET_INFO_MODAL";
export const SET_MXGROUP_OVERRIDE = "SET_MXGROUP_OVERRIDE";
export const SCROLL_WORKLOAD_FLAG = "SCROLL_WORKLOAD_FLAG";
export const GET_ATTACHED_TASKCARD = "GET_ATTACHED_TASKCARD";
export const RESET_ATTACHED_TASKCARD = "RESET_ATTACHED_TASKCARD";
export const APPLY_VISIT_FILTERS = "APPLY_VISIT_FILTERS";
export const GET_GROUPS_FOR_NONROUTINE = "GET_GROUPS_FOR_NONROUTINE";
export const GET_LINKED_GROUP_FOR_LINKED_NONROUTINE = "GET_LINKED_GROUP_FOR_LINKED_NONROUTINE"
export const GET_ATA_CODES = "GET_ATA_CODES";
export const SEARCH_HARMONIZED_WORKLOAD = "SEARCH_HARMONIZED_WORKLOAD";
export const RESET_SEARCH_HARMONIZED_WORKLOAD = "RESET_SEARCH_HARMONIZED_WORKLOAD"
export const GET_BASE_DASHBOARD_COUNTS_ACTIVE = "GET_BASE_DASHBOARD_COUNTS_ACTIVE";
export const GET_BASE_DASHBOARD_COUNTS_FUTURE = "GET_BASE_DASHBOARD_COUNTS_FUTURE";
export const FETCH_EXTENDED_ROUTING = "FETCH_EXTENDED_ROUTING";
export const SAVE_DASH1_NOTES = "SAVE_DASH1_NOTES";
export const SAVE_SHIFT_SUMMARY = "SAVE_SHIFT_SUMMARY";
export const SAVE_SHIFT_SUMMAR_NOTES_LOCALLY = "SAVE_SHIFT_SUMMAR_NOTES_LOCALLY";
export const GET_DASH1_NOTES = "GET_DASH1_NOTES";
export const RESET_DASH1_NOTES = "RESET_DASH1_NOTES";
export const FETCH_ADVANCE_WORKLOAD_SEARCH = "FETCH_ADVANCE_WORKLOAD_SEARCH";
export const FETCH_ACTIONTYPES_FOR_NOTES = "FETCH_ACTIONTYPES_FOR_NOTES";
export const RESET_DASH1_NOTES_FOR_NR = "RESET_DASH1_NOTES_FOR_NR";
export const FETCH_ASSIGNED_WORK = "FETCH_ASSIGNED_WORK";
export const HANDLE_BASE_ASSIGNWORK_TOGGLE = "HANDLE_BASE_ASSIGNWORK_TOGGLE";
export const SET_ASSIGNED_ROUTINES = "SET_ASSIGNED_ROUTINES";
export const SET_ASSIGN_TOGGLE = "SET_ASSIGN_TOGGLE";
export const SET_IS_ITEM_UNCHECKED = "SET_IS_ITEM_UNCHECKED";
export const GET_ASSIGNED_CREW = "GET_ASSIGNED_CREW";
export const GET_DASH1_NOTES_FOR_NR = "GET_DASH1_NOTES_FOR_NR";
export const FETCH_LINKED_EA = "FETCH_LINKED_EA";
export const DELETE_MXGROUP = "DELETE_MXGROUP";
export const CREW_WORK_LOAD_SEARCH = "CREW_WORK_LOAD_SEARCH";
export const CLEAR_CREW_WORK_LOAD_SEARCH = "CLEAR_CREW_WORK_LOAD_SEARCH";
export const GET_FLEET_CODES = "GET_FLEET_CODES";
export const GET_GROUPS_AND_WORKAREA_WITH_COUNTS = "GET_GROUPS_AND_WORKAREA_WITH_COUNTS";
export const ADD_PDFIMAGE_NONROUTINES = "ADD_PDFIMAGE_NONROUTINES";
export const GET_PDFIMAGE_NRS = "GET_PDFIMAGE_NRS";
export const DELETE_NR_PDF = "DELETE_NR_PDF";
//Template management actions
export const SEARCH_TEMPLATES = "SEARCH_TEMPLATES";
export const RESET_TEMPLATE_DATA = "RESET_TEMPLATE_DATA";
export const SET_TEMPLATE_SEARCH_CRITERIA = "SET_TEMPLATE_SEARCH_CRITERIA";
export const FETCH_TEMPLATE_DETAILS = "FETCH_TEMPLATE_DETAILS";
export const RESET_TEMPLATE_DETAILS = "RESET_TEMPLATE_DETAILS";
export const SET_SELECTED_TEMPLATE_GROUP = "SET_SELECTED_TEMPLATE_GROUP";
export const GET_TEMPLATE_TASKCARD_DETAILS = "GET_TEMPLATE_TASKCARD_DETAILS";
export const SEARCH_TASKCARDS = "SEARCH_TASKCARDS";
export const SEARCH_TASKCARDS_ENHANCED = "SEARCH_TASKCARDS_ENHANCED";
export const SAVE_TEMPLATE_TASKCARD_DETAILS = "SAVE_TEMPLATE_TASKCARD_DETAILS";
export const GET_TEMPLATE_MANAGEMENT_REF_DATA = "GET_TEMPLATE_MANAGEMENT_REF_DATA";
export const SAVE_UPDATE_MILESTONE = "SAVE_UPDATE_MILESTONE";
export const GET_TEMPLATE_MANAGEMENT_GROUPS_DATA = "GET_TEMPLATE_MANAGEMENT_GROUPS_DATA";
export const RESET_TASK_CARD_DETAILS_ERROR_MESSAGE = "RESET_TASK_CARD_DETAILS_ERROR_MESSAGE";
export const SET_TASKCARD_SEARCH_CRITERIA = "SET_TASKCARD_SEARCH_CRITERIA";
export const RESET_TASKCARD_SEARCH_DATA = "RESET_TASKCARD_SEARCH_DATA";
export const RESET_SAVE_UPDATE_MILESTONE = "RESET_SAVE_UPDATE_MILESTONE";
export const APPLY_TEMPLATE = "APPLY_TEMPLATE";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const RESET_SAVE_TEMPLATE_ERROR_MESSAGE = "RESET_SAVE_TEMPLATE_ERROR_MESSAGE";
export const RESET_APPLY_TEMPLATE = "RESET_APPLY_TEMPLATE";
export const SET_FAILED_NOTIFICATION_MESSAGE = "SET_FAILED_NOTIFICATION_MESSAGE";
export const SEARCH_WORKLOAD = "SEARCH_WORKLOAD";
export const SEARCH_WORKLOAD_ENHANCED = "SEARCH_WORKLOAD_ENHANCED";
export const GET_BASE_CREW_WORKLOAD = "GET_BASE_CREW_WORKLOAD";
export const UNASSIGN_CREW = "UNASSIGN_CREW";
export const GET_AVAIALABLE_CREW = "GET_AVAIALABLE_CREW";
export const GET_GROUPS_AND_WORKAREA = "GET_GROUPS_AND_WORKAREA";
export const GET_PROGRESS_FOR_TASKCARDS = "GET_PROGRESS_FOR_TASKCARDS";
export const SET_SELECTED_WORKLOAD = "SET_SELECTED_WORKLOAD";
export const SET_SELECTED_CREWS = "SET_SELECTED_CREWS";
export const SET_SELECTED_ASSIGNED_WORKLOAD = "SET_SELECTED_ASSIGNED_WORKLOAD";
export const CURRENT_CREW_DATE = "CURRENT_CREW_DATE";
export const UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY = "UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY";
export const UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY = "UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY";
export const GET_ZONAL_CARD_CONTENT = "GET_ZONAL_CARD_CONTENT";
export const UPDATE_PANEL_SIGNOFFS = "UPDATE_PANEL_SIGNOFFS";
export const ZONAL_CARD_SIGNOFF = "ZONAL_CARD_SIGNOFF";
export const SET_UPDATE_TEMPLATE_FLAG = "SET_UPDATE_TEMPLATE_FLAG";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const UPDATE_TEMPLATE_DETAILS = "UPDATE_TEMPLATE_DETAILS";
export const FETCH_TASK_AUDIT = "FETCH_TASK_AUDIT";
export const SET_TASK_AUDIT_SIGNOFF_OBJECT = "SET_TASK_AUDIT_SIGNOFF_OBJECT";
export const RESET_TASK_AUDIT_SIGNOFF_OBJECT = "RESET_TASK_AUDIT_SIGNOFF_OBJECT";
export const SIGNOFF_TASK_AUDIT_BLOCKS = "SIGNOFF_TASK_AUDIT_BLOCKS";
export const SET_ACTIVE_DASH1 = "SET_ACTIVE_DASH1";
export const APPROVE_REOPEN_ALL_BLOCKS = "APPROVE_REOPEN_ALL_BLOCKS";
export const APPROVE_ALL_BLOCKS = "APPROVE_ALL_BLOCKS";
export const REOPEN_ALL_BLOCKS = "REOPEN_ALL_BLOCKS";
export const RESET_FETCHTASKCARD_ERROR_MESSAGE = "RESET_FETCHTASKCARD_ERROR_MESSAGE";
export const FETCH_WORK_BINS = "FETCH_WORK_BINS";
export const FETCH_WORK_BINS_WORKLOAD = "FETCH_WORK_BINS_WORKLOAD";
export const CREATE_WORK_BIN = "CREATE_WORK_BIN";
export const SET_ACTIVE_WORK_BIN = "SET_ACTIVE_WORK_BIN";
export const DELETE_WORK_BIN = "DELETE_WORK_BIN";
export const UPDATE_SELECTED_WORKBIN_WORKLOAD = "UPDATE_SELECTED_WORKBIN_WORKLOAD";
export const MOVE_WORKLOAD_TO_WORKBIN = "MOVE_WORKLOAD_TO_WORKBIN";
export const DELETE_WORKLOAD_FROM_WORKBIN = "DELETE_WORKLOAD_FROM_WORKBIN";
export const MOVE_WORKLOAD_TO_MULTIPLE_WORKBIN = "MOVE_WORKLOAD_TO_MUTIPLE_WORKBIN";
export const DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN = "DELETE_WORKLOAD_FROM_MUTIPLE_WORKBIN";
export const UPDATE_SELECTED_WORKBINS = "UPDATE_SELECTED_WORKBINS";
export const ADD_WORKLOAD_TO_WORKBIN = "ADD_WORKLOAD_TO_WORKBIN";
export const GET_WORKLOAD_WORKBIN = "GET_WORKLOAD_WORKBIN";
export const SET_SELECTED_WORKLOAD_FOR_WORKBINS = "SET_SELECTED_WORKLOAD_FOR_WORKBINS";
export const GET_WORKBIN_WORKLOAD_COUNT = "GET_WORKBIN_WORKLOAD_COUNT";
export const FETCH_AUDIT_ACTIVITY_DETAILS = "FETCH_AUDIT_ACTIVITY_DETAILS";
export const UPDATE_DASH1_NOTES_COUNT = "UPDATE_DASH1_NOTES_COUNT";
export const RESET_WORKBINS = "RESET_WORKBINS";
export const RESET_TEMPLATE_WORKBIN_SEARCH_DATA = "RESET_TEMPLATE_WORKBIN_SEARCH_DATA";
export const ADD_ROTABLE_PARTS = "ADD_ROTABLE_PARTS";
export const RESET_ADD_ROTABLE_PARTS = "RESET_ADD_ROTABLE_PARTS";
export const SET_AUDIT_FILTERS = "SET_AUDIT_FILTERS";
export const IS_OPENE_LINK_CLICK_ACTION = "IS_OPENE_LINK_CLICK_ACTION";
export const TOGGLE_OPEN_CLOSE = "TOGGLE_OPEN_CLOSE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const GET_TEMPLATE_WORKBIN_WORKLOAD = "GET_TEMPLATE_WORKBIN_WORKLOAD";
export const FETCH_FLIFO = "FETCH_FLIFO";
export const UNASSIGN_ASSIGN_WORKLOAD = "UNASSIGN_ASSIGN_WORKLOAD";
export const UNASSIGN_ASSIGN_WORKLOAD_LOCAL_UPDATE = "UNASSIGN_ASSIGN_WORKLOAD_LOCAL_UPDATE";
export const FETCH_TEMPLATE_MILESTONES = "FETCH_TEMPLATE_MILESTONES"

// BASELINE
export const SET_INSP_WIDGET_FLOW_FLAG = "SET_INSP_WIDGET_FLOW_FLAG";
export const SET_WORKBIN_WIDGET_FLOW_FLAG = "SET_WORKBIN_WIDGET_FLOW_FLAG";
export const RESET_SEARCH_WORKLOAD = "RESET_SEARCH_WORKLOAD";
export const FETCH_BASELINE_INDUCTION = 'FETCH_BASELINE_INDUCTION';
export const FETCH_BASELINE_STATS = 'FETCH_BASELINE_STATS';
export const UPDATE_BASELINE_DATE = 'UPDATE_BASELINE_DATE';
export const FETCH_BASELINE_REPORT_DETAILS = "FETCH_BASELINE_REPORT_DETAILS";
export const UPDATE_VISITINFO_BASELINE = "UPDATE_VISITINFO_BASELINE";
export const BASELINE_FILTERS = "BASELINE_FILTERS";
export const UPDATE_BASELINE_DETAILS = "UPDATE_BASELINE_DETAILS";
export const GET_LOGPAGES_COUNT = "GET_LOGPAGES_COUNT";
export const GET_NONROUTINES_COUNT = "GET_NONROUTINES_COUNT";
export const GET_SCHEDULEDWORK_COUNT = "GET_SCHEDULEDWORK_COUNT";

export const SELF_ASSOCIATE_WL = "SELF_ASSOCIATE_WL";

//MYSHIFT

export const FETCH_WORKLOAD_BY_TYPE = "FETCH_WORKLOAD_BY_TYPE";
export const FETCH_WORKLOAD_BY_TECH = "FETCH_WORKLOAD_BY_TECH";
export const FETCH_LEAD_MODULE_CREWS = "FETCH_LEAD_MODULE_CREWS";
export const SET_SELECTED_LEAD_MODULE_CREW = "SET_SELECTED_LEAD_MODULE_CREW";
export const ASSIGN_LEAD_ASSOCIATES_TECH = "ASSIGN_LEAD_ASSOCIATES_TECH";
export const FETCH_CREW_WORKLOAD_LEAD_MODULE = "FETCH_CREW_WORKLOAD_LEAD_MODULE";
export const FETCH_OPENE_NONROUTINES_LEAD_MODULE = "FETCH_OPENE_NONROUTINES_LEAD_MODULE";
export const UPDATE_ASSIGNEES = "UPDATE_ASSIGNEES";
export const UPDATE_TASK_CARD_ASSIGNEES = "UPDATE_TASK_CARD_ASSIGNEES";
export const UPDATE_TASK_CARD_ASSIGNEES_DUP = "UPDATE_TASK_CARD_ASSIGNEES_DUP";
export const UPDATE_LOGPAGE_ASSIGNEES = "UPDATE_LOGPAGE_ASSIGNEES";
export const UPDATE_OPEN_LOGPAGE_ASSIGNEES = "UPDATE_OPEN_LOGPAGE_ASSIGNEES";
export const SET_PLANNING_CALL = "SET_PLANNING_CALL";
export const UPDATE_NR_NOTES_COUNT_MY_SHIFT = "UPDATE_NR_NOTES_COUNT_MY_SHIFT";
export const UPDATE_DASH1_NOTES_COUNT_MY_SHIFT = "UPDATE_DASH1_NOTES_COUNT_MY_SHIFT";
export const RESET_MYSHIFT_DATA = "RESET_MYSHIFT_DATA";
export const GET_CREW_COMMENTS = "GET_CREW_COMMENTS";

export const UPDATE_SELECT_ALL_WORKBINS = 'UPDATE_SELECT_ALL_WORKBINS'

/** shift hand over report */
export const SAVE_CREW_TECH_COMMENTS = 'SAVE_CREW_TECH_COMMENTS'
export const GET_CREW_TECH_COMMENTS = 'GET_CREW_TECH_COMMENTS'
export const SHR_FETCH_CREW_WORKLOAD_LEAD_MODULE = 'SHR_FETCH_CREW_WORKLOAD_LEAD_MODULE'
export const FETCH_SHR_STATS = "FETCH_SHR_STATS"
export const FETCH_SHIFTS_AND_CREWS = "FETCH_SHIFTS_AND_CREWS"
export const SET_SELECTED_SHIFT = "SET_SELECTED_SHIFT"
export const SHR_SAVE_CREW_COMMENT = 'SHR_SAVE_CREW_COMMENT'
export const SHR_GET_CREW_COMMENT = 'SHR_GET_CREW_COMMENT'

export const SAVE_TEST_HOP_DATE = 'SAVE_TEST_HOP_DATE'
export const SAVE_SHIFT_SUMMARY_NOTES = 'SAVE_SHIFT_SUMMARY_NOTES'
export const GET_SHIFT_SUMMARY = 'GET_SHIFT_SUMMARY'
export const SAVE_TECH_CREW_COMMENTS_LOCAL = 'SAVE_TECH_CREW_COMMENTS_LOCAL'
export const SAVE_FEEDBACK = 'SAVE_FEEDBACK'
export const SAVE_RELEASE_NOTE_FLAG = 'SAVE_RELEASE_NOTE_FLAG'
export const SAVE_LAST_BLOCK_NUMBER = 'SAVE_LAST_BLOCK_NUMBER'
export const GET_OPTMIZED_STATUSES = 'GET_OPTMIZED_STATUSES'
export const GET_HISTORIC_REPORTS = 'GET_HISTORIC_REPORTS'
export const FINALIZE_REPORT = 'FINALIZE_REPORT'
export const GET_SHR_PDF_IMAGES = 'GET_SHR_PDF_IMAGES'

// Aircraft status
export const GET_AIRCRAFT_DATA_FOR_VISIT = 'GET_AIRCRAFT_DATA_FOR_VISIT';
export const GET_AIRCRAFT_DATA_FOR_24HRS = 'GET_AIRCRAFT_DATA_FOR_24HRS';
export const GET_AIRCRAFT_DATA_FOR_12HRS = 'GET_AIRCRAFT_DATA_FOR_12HRS';
export const SEARCH_WORKLOAD_AIRCRAFT_STATUS = 'SEARCH_WORKLOAD_AIRCRAFT_STATUS';
export const SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED = 'SEARCH_WORKLOAD_AIRCRAFT_STATUS_SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED';
export const GET_PROGRESS_FOR_TASKCARDS_AIRCRAFT_STATUS = 'GET_PROGRESS_FOR_TASKCARDS_AIRCRAFT_STATUS';
export const SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED_PROGRESS = 'SEARCH_WORKLOAD_AIRCRAFT_STATUS_COMPLETED_PROGRESS';

export const VIEW_MILESTONE_GANTT_CHART = "VIEW_MILESTONE_GANTT_CHART"
export const VIEW_MILESTONE_GANTT_CHART_SIDE_PANEL = "VIEW_MILESTONE_GANTT_CHART_SIDE_PANEL"
export const EXPAND_MILESTONE_GANTT_CHART = "EXPAND_MILESTONE_GANTT_CHART"

export const SET_KPI_FILTER = 'SET_KPI_FILTER';
export const SET_CREWS_FOR_ASSIGN_UNASSIGN_MODAL = 'SET_CREWS_FOR_ASSIGN_UNASSIGN_MODAL'