import {
  FETCH_WORKLOAD_BY_TYPE, FETCH_WORKLOAD_BY_TECH, FETCH_LEAD_MODULE_CREWS,
  SET_SELECTED_LEAD_MODULE_CREW, ASSIGN_LEAD_ASSOCIATES_TECH, FETCH_CREW_WORKLOAD_LEAD_MODULE,
  FETCH_OPENE_NONROUTINES_LEAD_MODULE, UPDATE_ASSIGNEES, UPDATE_TASK_CARD_ASSIGNEES,
  UPDATE_LOGPAGE_ASSIGNEES, UPDATE_OPEN_LOGPAGE_ASSIGNEES, SET_PLANNING_CALL, UPDATE_NR_NOTES_COUNT_MY_SHIFT,
  UPDATE_DASH1_NOTES_COUNT_MY_SHIFT, RESET_MYSHIFT_DATA, UPDATE_TASK_CARD_ASSIGNEES_DUP, SET_CREWS_FOR_ASSIGN_UNASSIGN_MODAL
} from '../actions/actionTypes';

const initialState = {
  workloadByType: null,
  workloadByTech: null,
  leadModuleCrews: null,
  leadModuleCrewsDetails: null,
  isLoadingLeadModuleCrews: false,
  isLoadingLeadAssociatesTech: false,
  leadAssociatesTech: null,
  selectedCrew: null,
  crewWorkload: null,
  techWorkloadList: null,
  unAssignedWorkload: null,
  futureWorkload: null,
  crewWorkloadForSHR: null,
  techCrewWorkloadForSHRList: null,
  isLoadingCrewWorkloadLeadModule: false,
  isLoadingOpenENonRoutines: false,
  openENonRoutinesLeadModule: null,
  myshiftActiveTab: 'crewWorkload',
  isViewByTech: false,
  isUpCommingWork: false,
  planningCall: null,
  unAssignedWorkloadCount: 0
}
/* active tab is the selected tab
    toggle state is the toggle button for each selected tab
    1. crew workload => tab is 'view by type' or 'view by tech'
    2. unassigned work => tab is 'view upcomming work' -> true/false
    3. open e non routine => tab is 'View all open e' -> true/false
*/
const getStateKey = (activeTab, currentState) => {
  switch (activeTab) {
    case 'crewWorkload':
      return !currentState.isViewByTech ? 'crewWorkload' : 'techWorkloadList';
    case 'unAssignedWork':
      return !currentState.isUpCommingWork ? 'unAssignedWorkload' : 'futureWorkload'
    case 'openENonRoutine':
      // return !toggleState ?  'openENonRoutinesLeadModule' :''
      return '';
  } 
}

const getUniqueIdentifier = (workItems) => {
  return `${((workItems['paperTaskCardId'] === 0) || (workItems['zoneCardId'] === 0)) ? workItems.dash1Number : workItems.cardId}${workItems.dash8Number}${workItems.serialNumber}${workItems.workControlNumber}`
}

// Get the item is the current tab is 'techWorkloadList'
const getItemFromTechWL = (taskcardType, id, state) => {
  const tcItemKey = (carditem) => {
    switch (taskcardType.toLowerCase()) {
      case 'nonroutines':
        return carditem['nonRoutineNumber'];
      case 'logpages':
        return carditem['logpageNumber'];
      case 'openlogpages':
        return carditem['logpageNumber'];
      default:
        return getUniqueIdentifier(carditem)
    }
  }
  const tcarditem = [...state.techWorkloadList].map(users => {
    return users?.['openTechWorkload'][taskcardType]?.find(tcItem => tcItemKey(tcItem) === id)
  }).filter(Boolean);
  return tcarditem?.length ? tcarditem[0] : {}
}
// get the active item from store
const getCurrentItem = (activeStateKey, state, taskcardType, id) => {
  if (activeStateKey !== 'techWorkloadList') {
    return [...state[activeStateKey][taskcardType]].find(listItem => getUniqueIdentifier(listItem) === id) || {};
  } else if (activeStateKey === 'techWorkloadList') {
    const tcarditem = getItemFromTechWL(taskcardType, id, state)
    return tcarditem
  }

}

const updateMyShiftWorkloadNotes = (workload, workloadType, payload) => {
  if (payload.isDup) {
    workload = updateMyShiftWorkloadNotesDup(workload, payload);
  }
  else if ((`${workloadType === "zonal" ? workload.cardId : workload.dash1Number}${workload.dash8Number}${workload.serialNumber}${workload.workControlNumber}` === `${payload.queryStringValues?.taskcard}${payload.queryStringValues?.dash8}${payload.queryStringValues?.serialno}${payload.queryStringValues?.wcno}`)
    || (workloadType === "nr" && workload.nonRoutineID === payload.queryStringValues.nonroutineid)
  ) {
    workload.notesCount += 1;
    workload.lastNote = `${payload.lastNotes}${payload.workbin ? ` - Task Card has been added to workbin ${payload.workbin.workBinName}` : ''}`;
    if (workload.hasOwnProperty('workStop')) {
      workload.workStop = payload.queryStringValues.workStop ?? workload.workStop;
      workload.notesActionTypeAbbreviated = payload.queryStringValues.abbreviatedActionType ?? ''
    }
    workload.lastNoteDetail = payload.lastNoteDetail;
  }
  return workload;
}

const updateMyShiftWorkloadNotesDup = (workload, payload) => {

  if (workload.dash1Number === payload.queryStringValues?.taskcard
    && payload.queryStringValues.optimizedDash8s?.find(d8 => d8.ncaPartNumber === workload.dash8Number))
  {
    workload.notesCount += 1;
    workload.lastNote = `${payload.lastNotes}${payload.workbin ? ` - Task Card has been added to workbin ${payload.workbin.workBinName}` : ''}`;
    if (workload.hasOwnProperty('workStop')) {
      workload.workStop = payload.queryStringValues.workStop ?? workload.workStop;
      workload.notesActionTypeAbbreviated = payload.queryStringValues.abbreviatedActionType ?? ''
    }
    workload.lastNoteDetail = payload.lastNoteDetail;
  }
  return workload;
}

const getAssignedUserList = techs => techs.reduce((acc, curr) => [...acc, curr.empId], [])
const myShiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_WORKLOAD_BY_TYPE}`: {
      return {
        ...state,
        workloadByType: action.payload
      }
    }
    case `${FETCH_WORKLOAD_BY_TECH}`: {
      return {
        ...state,
        workloadByTech: action.payload
      }
    }

    case `${FETCH_LEAD_MODULE_CREWS}_PENDING`: {
      return {
        ...state,
        isLoadingLeadModuleCrews: true,
        loadModuleCrews: null,
        loadModuleCrewsDetails: null
      }
    }
    case `${FETCH_LEAD_MODULE_CREWS}_FULFILLED`: {
      return {
        ...state,
        isLoadingLeadModuleCrews: false,
        leadModuleCrews: action.payload?.crewDetailsForLeadModule?.shiftCrews?.map(crew => { return { "crewName": crew.crewName, "crewConfigId": crew.crewConfigId } }),
        loadModuleCrewsDetails: action.payload?.crewDetailsForLeadModule?.shiftCrews
      }
    }

    case `${SET_CREWS_FOR_ASSIGN_UNASSIGN_MODAL}`: {
      return {
        ...state,
        leadModuleCrews : action.payload.shiftCrews.map(crew => { return { "crewName": crew.crewName, "crewConfigId": crew.crewConfigId } }),
        loadModuleCrewsDetails: action.payload.shiftCrews
      }      
    }
    case `${ASSIGN_LEAD_ASSOCIATES_TECH}_PENDING`: {
      return {
        ...state,
        isLoadingLeadAssociatesTech: true,
        leadAssociatesTech: null,
      }
    }
    case `${ASSIGN_LEAD_ASSOCIATES_TECH}_FULFILLED`: {
      return {
        ...state,
        isLoadingLeadAssociatesTech: false,
        leadAssociatesTech: action.payload
      }
    }
    case `${SET_SELECTED_LEAD_MODULE_CREW}`: {
      return {
        ...state,
        selectedCrew: action.payload
      }
    }
    case `${FETCH_CREW_WORKLOAD_LEAD_MODULE}_PENDING`: {
      return {
        ...state,
        isLoadingCrewWorkloadLeadModule: true,
        crewWorkload: null,
        techWorkloadList: null,
        unAssignedWorkload: null,
        unAssignedWorkloadCount: 0,
        futureWorkload: null,
        crewWorkloadForSHR: null,
        techCrewWorkloadForSHRList: null
      }
    }
    case `UPDATE_ACTIVE_TAB`:
      return {
        ...state,
        myshiftActiveTab: action.payload,
        isViewByTech: false,
        isUpCommingWork: false,
        isAllOpenE: false,
      }
    case 'UPDATE_VIEW_BY_TECH':
      return {
        ...state,
        isViewByTech: action.payload
      }
    case 'UPDATE_UPCOMING_WORK':
      return {
        ...state,
        isUpCommingWork: action.payload
      }
    case 'UPDATE_UNASSIGNED_WORKLOAD_COUNT':
      return {
        ...state,
        unAssignedWorkloadCount: action.payload
      }
    case `${FETCH_CREW_WORKLOAD_LEAD_MODULE}_FULFILLED`: {

      return {
        ...state,
        isLoadingCrewWorkloadLeadModule: false,
        crewWorkload: action.payload?.getCrewWorkloadForLeadModule?.crewWorkload,
        techWorkloadList: action.payload?.getCrewWorkloadForLeadModule?.techWorkloadList,
        unAssignedWorkload: action.payload?.getCrewWorkloadForLeadModule?.unAssignedWorkload,
        futureWorkload: action.payload?.getCrewWorkloadForLeadModule?.futureWorkload,
        crewWorkloadForSHR: action.payload?.getCrewWorkloadForLeadModule?.crewWorkloadForSHR,
        techCrewWorkloadForSHRList: action.payload?.getCrewWorkloadForLeadModule?.techCrewWorkloadForSHRList
      }
    }
    case UPDATE_ASSIGNEES: {
      const { id, techs, unAssignedUsers } = action.payload;
      const stateValue = getStateKey(state.myshiftActiveTab, state);
      // Updating 
      const updatedWorkLoad = (stateValue !== 'techWorkloadList' && stateValue) ? [...state[stateValue].nonRoutines] : stateValue === 'techWorkloadList' ? [] : [...state.openENonRoutinesLeadModule];
      let updateListItem = [...updatedWorkLoad];
      let updatedTechWorkloadList = [];
      let updatedCrewWorkload = null;
      let unAssignedWorkload = { ...state.unAssignedWorkload };
      const assignedUsersList = getAssignedUserList(techs);
      // const currentItem = getCurrentItem(stateValue, state, taskcardType, id); 
      const currentItem = stateValue === 'techWorkloadList' ? getItemFromTechWL('nonRoutines', id, state) : updateListItem.find(listItem => listItem?.nonRoutineNumber === id) || {};
      const currentAssignees = techs.map(tech => ({
        ...tech,
        assigneeFullName: `${tech.firstName} ${tech.lastName}`
      }))
      Object.keys(currentItem).length && (currentItem.assignees = currentAssignees)

      // if user is on 'crewworkload' and item has no assigned user, remove the updated item from 'unassigned' work tab and 'open e'
      if (state.myshiftActiveTab === 'crewWorkload' || state.myshiftActiveTab === 'openENonRoutine') {
        unAssignedWorkload = { ...unAssignedWorkload, nonRoutines: currentAssignees.length ? unAssignedWorkload['nonRoutines'].filter(nrItem => nrItem?.nonRoutineNumber !== id) : [...unAssignedWorkload['nonRoutines'], ...(Object.keys(currentItem).length ? [currentItem] : [])] }
      }
      if (state.myshiftActiveTab === 'unAssignedWork' && stateValue !== 'futureWorkload') {
        updateListItem = updateListItem.filter(listItem => listItem?.nonRoutineNumber !== id);
      } else {
        // if techs are empty, ie all the assignees are removed, remove item
        updateListItem = stateValue !== 'techWorkloadList' ? updateListItem.map(item => {
          if (item?.nonRoutineNumber === id) {
            item.assignees = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return item
        }) : [];
      }

      // Check if current tab is not crew workload, then update crey workload also
      if (stateValue !== 'crewWorkload') {
        const isFutureWL = [...state.crewWorkload.nonRoutines]?.find(nr => nr.nonRoutineNumber === id)
        updatedCrewWorkload = isFutureWL ? [...state.crewWorkload.nonRoutines].map(nr => {
          if (nr.nonRoutineNumber === id) {
            nr['assignees'] = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return nr
        }) : [...state.crewWorkload.nonRoutines, ...(Object.keys(currentItem).length ? [currentItem] : [])]
      }

      updatedTechWorkloadList = [...state.techWorkloadList].map(users => {
        if (unAssignedUsers.includes(users.techId)) {
          users.openTechWorkload.nonRoutines = users.openTechWorkload.nonRoutines.filter(item => item.nonRoutineNumber !== id)
        }
        if (assignedUsersList.includes(users.techId)) {
          // users.openTechWorkload.nonRoutines.push(currentItem)
          const _currentItem = !stateValue ? [...state.openENonRoutinesLeadModule].find(listItem => listItem.nonRoutineNumber === id) : [...state.crewWorkload['nonRoutines']].find(listItem => listItem.nonRoutineNumber === id);
          if (_currentItem) _currentItem.assignees = currentAssignees;
          const _itemInList = users.openTechWorkload['nonRoutines'].find(i => i.nonRoutineNumber === id)
          users.openTechWorkload['nonRoutines'] = _itemInList ? [...users.openTechWorkload['nonRoutines']].map(nR => {
            if (nR.nonRoutineNumber === id) nR.assignees = currentAssignees;
            return nR
          }) : [...users.openTechWorkload['nonRoutines'], _currentItem || currentItem]
        }
        return users
      });

      return {
        ...state,
        ...(stateValue === 'techWorkloadList' ? {} : stateValue ? { [stateValue]: { ...state[stateValue], nonRoutines: updateListItem } } : { openENonRoutinesLeadModule: updateListItem, crewWorkload: { ...state.crewWorkload, nonRoutines: [...state.crewWorkload.nonRoutines, ...(Object.keys(currentItem).length ? [currentItem] : [])] } }),
        ...(updatedTechWorkloadList.length ? { techWorkloadList: updatedTechWorkloadList } : {}),
        ...(updatedCrewWorkload != null && { crewWorkload: { ...state.crewWorkload, nonRoutines: updatedCrewWorkload } }),
        ...(state.myshiftActiveTab !== 'unAssignedWork' ? { unAssignedWorkload: unAssignedWorkload } : {})
      }


    }
    case UPDATE_TASK_CARD_ASSIGNEES_DUP: {
      const { techs, key, id, taskcardType, unAssignedUsers, workItems } = action.payload;
      const activeTabSection = getStateKey(state.myshiftActiveTab, state);

      const assignedUsersList = getAssignedUserList(techs)
      const activeTabWorkItems = activeTabSection !== 'techWorkloadList' ? [...state[activeTabSection][taskcardType]] : []
      let updateListItem = [...activeTabWorkItems]
      let updatedTechWorkloadList = [...state.techWorkloadList];
      let updatedCrewWorkload = [...state.crewWorkload[taskcardType]];
      let unAssignedWorkload = { ...state.unAssignedWorkload };
      const currentAssignees = techs.map(tech => ({
        ...tech,
        assigneeFullName: `${tech.firstName} ${tech.lastName}`
      }))

      workItems.map(wItem => {
        const workItemId = `${wItem.mxSubWorkItem}${wItem.mxWorkItem}${wItem.serialNumber}${wItem.workControlNumber}`        
        // The current task card item
        const currentItem = getCurrentItem(activeTabSection, state, taskcardType, workItemId);
      
        Object.keys(currentItem).length && (currentItem.assignees = currentAssignees)
        // if user is on 'crewworkload' and item has no assigned user, remove the updated item from 'unassigned' work tab 

        if (state.myshiftActiveTab === 'crewWorkload') {
          unAssignedWorkload = { ...unAssignedWorkload, [taskcardType]: currentAssignees.length ? unAssignedWorkload[taskcardType].filter(taskCard => getUniqueIdentifier(taskCard) !== workItemId) : [...unAssignedWorkload[taskcardType], ...(Object.keys(currentItem).length ? [currentItem] : [])] }
        }
        // if tab is in 'un assigned'
        if (state.myshiftActiveTab === 'unAssignedWork' && activeTabSection !== 'futureWorkload') {
          updateListItem = updateListItem.filter(listItem => getUniqueIdentifier(listItem) !== workItemId);
        }
        else {
          updateListItem = activeTabSection !== 'techWorkloadList' ? [...state[activeTabSection][taskcardType]].map(taskCard => {
            if (taskCard[key] === id) {
              taskCard['assignees'] = techs.map(tech => ({
                ...tech,
                assigneeFullName: `${tech.firstName} ${tech.lastName}`
              }))
            }
            return taskCard
          }) : []
        }

        // Check if current tab is not crew workload, then update crew workload as well
        if (activeTabSection !== 'crewWorkload') {
          const isFutureWL = updatedCrewWorkload?.find(tcard => getUniqueIdentifier(tcard) === workItemId)
          updatedCrewWorkload = isFutureWL ?
            updatedCrewWorkload.map(taskCard => {
              if (getUniqueIdentifier(taskCard) === id) {
                // if(taskCard[key] === id){
                taskCard['assignees'] = techs.map(tech => ({
                  ...tech,
                  assigneeFullName: `${tech.firstName} ${tech.lastName}`
                }))
              }
              return taskCard
            })
            : currentItem.hasOwnProperty('cardTitle') ?
              [...state.crewWorkload[taskcardType], currentItem]
              :
              updatedCrewWorkload
        }

        updatedTechWorkloadList = updatedTechWorkloadList.map(users => {
          if (unAssignedUsers.includes(users.techId)) {
            users.openTechWorkload[taskcardType] = users.openTechWorkload[taskcardType].filter(item => getUniqueIdentifier(item) !== workItemId)
          }
          if (assignedUsersList.includes(users.techId)) {
            const _currentItem = updatedCrewWorkload.find(listItem => getUniqueIdentifier(listItem) === workItemId);
            if (_currentItem) _currentItem.assignees = currentAssignees;
            const _itemInList = users.openTechWorkload[taskcardType].find(i => getUniqueIdentifier(i) === workItemId)
            // if(_itemInList) _itemInList.assignees = currentAssignees;
            users.openTechWorkload[taskcardType] = _itemInList ? [...users.openTechWorkload[taskcardType]].map(tc => {
              if (getUniqueIdentifier(tc) === workItemId) tc.assignees = currentAssignees;
              return tc;
            }) : [...users.openTechWorkload[taskcardType], _currentItem || currentItem]
          }
          return users
        });        
      })
      
      return {
        ...state,
        techWorkloadList: updatedTechWorkloadList,
        ...(activeTabSection === 'techWorkloadList' ? {} : activeTabSection ? { [activeTabSection]: { ...state[activeTabSection], [taskcardType]: updateListItem } } : {}),
        ...(updatedCrewWorkload != null && { crewWorkload: { ...state.crewWorkload, [taskcardType]: updatedCrewWorkload } }),
        ...(state.myshiftActiveTab !== 'unAssignedWork' ? { unAssignedWorkload: unAssignedWorkload } : {})
      }
    }
      
    case UPDATE_TASK_CARD_ASSIGNEES: {
      const { techs, key, id, taskcardType, unAssignedUsers } = action.payload;
      const stateValue = getStateKey(state.myshiftActiveTab, state);

      const assignedUsersList = getAssignedUserList(techs)
      const currentLogPages = stateValue !== 'techWorkloadList' ? [...state[stateValue][taskcardType]] : []
      let updateListItem = [...currentLogPages]
      let updatedTechWorkloadList = [];
      let updatedCrewWorkload = null;
      let unAssignedWorkload = { ...state.unAssignedWorkload };
      // The current task card item
      const currentItem = getCurrentItem(stateValue, state, taskcardType, id);
      const currentAssignees = techs.map(tech => ({
        ...tech,
        assigneeFullName: `${tech.firstName} ${tech.lastName}`
      }))
      Object.keys(currentItem).length && (currentItem.assignees = currentAssignees)
      // if user is on 'crewworkload' and item has no assigned user, remove the updated item from 'unassigned' work tab 

      if (state.myshiftActiveTab === 'crewWorkload') {
        unAssignedWorkload = { ...unAssignedWorkload, [taskcardType]: currentAssignees.length ? unAssignedWorkload[taskcardType].filter(taskCard => getUniqueIdentifier(taskCard) !== id) : [...unAssignedWorkload[taskcardType], ...(Object.keys(currentItem).length ? [currentItem] : [])] }
      }
      // if tab is in 'un assigned'
      if (state.myshiftActiveTab === 'unAssignedWork' && stateValue !== 'futureWorkload') {
        updateListItem = updateListItem.filter(listItem => getUniqueIdentifier(listItem) !== id);
      } else {
        updateListItem = stateValue !== 'techWorkloadList' ? [...state[stateValue][taskcardType]].map(taskCard => {
          if (taskCard[key] === id) {
            taskCard['assignees'] = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return taskCard
        }) : []
      }

      // Check if current tab is not crew workload, then update crey workload also
      if (stateValue !== 'crewWorkload') {
        const isFutureWL = [...state.crewWorkload[taskcardType]]?.find(tcard => getUniqueIdentifier(tcard) === id)
        updatedCrewWorkload = isFutureWL ?
          [...state.crewWorkload[taskcardType]].map(taskCard => {
            if (getUniqueIdentifier(taskCard) === id) {
              // if(taskCard[key] === id){
              taskCard['assignees'] = techs.map(tech => ({
                ...tech,
                assigneeFullName: `${tech.firstName} ${tech.lastName}`
              }))
            }
            return taskCard
          })
          :
          currentItem.hasOwnProperty('cardTitle') ?
            [...state.crewWorkload[taskcardType], currentItem]
            :
            [...state.crewWorkload[taskcardType]]
      }

      updatedTechWorkloadList = [...state.techWorkloadList].map(users => {
        if (unAssignedUsers.includes(users.techId)) {
          users.openTechWorkload[taskcardType] = users.openTechWorkload[taskcardType].filter(item => getUniqueIdentifier(item) !== id)
        }
        if (assignedUsersList.includes(users.techId)) {
          const _currentItem = [...state.crewWorkload[taskcardType]].find(listItem => getUniqueIdentifier(listItem) === id);
          if (_currentItem) _currentItem.assignees = currentAssignees;
          const _itemInList = users.openTechWorkload[taskcardType].find(i => getUniqueIdentifier(i) === id)
          // if(_itemInList) _itemInList.assignees = currentAssignees;
          users.openTechWorkload[taskcardType] = _itemInList ? [...users.openTechWorkload[taskcardType]].map(tc => {
            if (getUniqueIdentifier(tc) === id) tc.assignees = currentAssignees;
            return tc;
          }) : [...users.openTechWorkload[taskcardType], _currentItem || currentItem]
        }
        return users
      });
      return {
        ...state,
        techWorkloadList: updatedTechWorkloadList,
        ...(stateValue === 'techWorkloadList' ? {} : stateValue ? { [stateValue]: { ...state[stateValue], [taskcardType]: updateListItem } } : {}),
        ...(updatedCrewWorkload != null && { crewWorkload: { ...state.crewWorkload, [taskcardType]: updatedCrewWorkload } }),
        ...(state.myshiftActiveTab !== 'unAssignedWork' ? { unAssignedWorkload: unAssignedWorkload } : {})
      }
    }

    case UPDATE_LOGPAGE_ASSIGNEES: {
      const { techs, id, unAssignedUsers } = action.payload;
      const stateValue = getStateKey(state.myshiftActiveTab, state);

      const assignedUsersList = getAssignedUserList(techs)
      const currentLogPages = stateValue !== 'techWorkloadList' ? [...state[stateValue].logpages] : []
      let updateListItem = [...currentLogPages]
      let updatedTechWorkloadList = [];
      let updatedCrewWorkload = null;
      let unAssignedWorkload = { ...state.unAssignedWorkload };
      const currentItem = stateValue === 'techWorkloadList' ? getItemFromTechWL('logpages', id, state) : updateListItem.find(listItem => listItem?.logpageNumber === id) || {};
      const currentAssignees = techs.map(tech => ({
        ...tech,
        assigneeFullName: `${tech.firstName} ${tech.lastName}`
      }))
      Object.keys(currentItem).length && (currentItem.assignees = currentAssignees)

      // if user is on 'crewworkload' and item has no assigned user, remove the updated item from 'unassigned' work tab 

      if (state.myshiftActiveTab === 'crewWorkload') {
        unAssignedWorkload = { ...unAssignedWorkload, logpages: currentAssignees.length ? unAssignedWorkload.logpages.filter(lps => lps?.logpageNumber !== id) : [...unAssignedWorkload.logpages, ...(Object.keys(currentItem).length ? [currentItem] : [])] }
      }

      // if tab is in 'un assigned'
      if (state.myshiftActiveTab === 'unAssignedWork' && stateValue !== 'futureWorkload') {
        updateListItem = updateListItem.filter(listItem => listItem?.logpageNumber !== id);
      } else {
        updateListItem = stateValue !== 'techWorkloadList' ? [...state[stateValue].logpages].map(logpage => {
          if (logpage['logpageNumber'] === id) {
            logpage['assignees'] = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return logpage
        }) : []

      }

      // Check if current tab is not crew workload, then update crey workload also
      if (stateValue !== 'crewWorkload') {
        const isFutureWL = [...state.crewWorkload.logpages]?.find(lpage => lpage.logpageNumber === id)
        updatedCrewWorkload = isFutureWL ? [...state.crewWorkload.logpages].map(lp => {
          if (lp.logpageNumber === id) {
            lp['assignees'] = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return lp
        }) : [...state.crewWorkload.logpages, ...(Object.keys(currentItem).length ? [currentItem] : [])]
      }

      updatedTechWorkloadList = [...state.techWorkloadList].map(users => {
        if (unAssignedUsers.includes(users.techId)) {
          users.openTechWorkload.logpages = users.openTechWorkload.logpages.filter(item => item.logpageNumber !== id)
        }
        if (assignedUsersList.includes(users.techId)) {
          const _currentItem = [...state.crewWorkload['logpages']].find(listItem => listItem.logpageNumber === id);
          if (_currentItem) _currentItem.assignees = currentAssignees;
          const _itemInList = users.openTechWorkload.logpages.find(i => i.logpageNumber === id)
          users.openTechWorkload.logpages = _itemInList ? [...users.openTechWorkload.logpages].map(lp => {
            if (lp.logpageNumber === id) lp.assignees = currentAssignees;
            return lp;
          }) : [...users.openTechWorkload.logpages, _currentItem || currentItem]
        }
        return users
      });
      return {
        ...state,
        ...(updatedTechWorkloadList.length ? { techWorkloadList: updatedTechWorkloadList } : {}),
        ...(stateValue === 'techWorkloadList' ? {} : stateValue ? { [stateValue]: { ...state[stateValue], logpages: updateListItem } } : {}),
        ...(updatedCrewWorkload != null && { crewWorkload: { ...state.crewWorkload, logpages: updatedCrewWorkload } }),
        ...(state.myshiftActiveTab !== 'unAssignedWork' ? { unAssignedWorkload: unAssignedWorkload } : {})
      }
    }


    case UPDATE_OPEN_LOGPAGE_ASSIGNEES: {
      const { techs, id, unAssignedUsers } = action.payload;
      const stateValue = getStateKey(state.myshiftActiveTab, state);

      const assignedUsersList = getAssignedUserList(techs)
      const currentLogPages = stateValue !== 'techWorkloadList' ? [...state[stateValue].openLogpages] : []
      let updateListItem = [...currentLogPages]
      let updatedTechWorkloadList = [];
      let updatedCrewWorkload = null;
      let unAssignedWorkload = { ...state.unAssignedWorkload };
      const currentItem = stateValue === 'techWorkloadList' ? getItemFromTechWL('openLogpages', id, state) : updateListItem.find(listItem => listItem?.logpageNumber === id) || {};
      const currentAssignees = techs.map(tech => ({
        ...tech,
        assigneeFullName: `${tech.firstName} ${tech.lastName}`
      }))
      Object.keys(currentItem).length && (currentItem.assignees = currentAssignees)

      // if user is on 'crewworkload' and item has no assigned user, remove the updated item from 'unassigned' work tab 

      if (state.myshiftActiveTab === 'crewWorkload') {
        unAssignedWorkload = { ...unAssignedWorkload, openLogpages: currentAssignees.length ? unAssignedWorkload.openLogpages.filter(olps => olps?.logpageNumber !== id) : [...unAssignedWorkload.openLogpages, ...(Object.keys(currentItem).length ? [currentItem] : [])] }
      }

      // if tab is in 'un assigned'
      if (state.myshiftActiveTab === 'unAssignedWork' && stateValue !== 'futureWorkload') {
        updateListItem = updateListItem.filter(listItem => listItem?.logpageNumber !== id);
      } else {
        updateListItem = stateValue !== 'techWorkloadList' ? [...state[stateValue].openLogpages].map(logpage => {
          if (logpage['logpageNumber'] === id) {
            logpage['assignees'] = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return logpage
        }) : []
      }

      // Check if current tab is not crew workload, then update crey workload also
      if (stateValue !== 'crewWorkload') {
        const isFutureWL = [...state.crewWorkload.openLogpages]?.find(olpage => olpage.logpageNumber === id)
        updatedCrewWorkload = isFutureWL ? [...state.crewWorkload.openLogpages].map(olp => {
          if (olp.logpageNumber === id) {
            olp['assignees'] = techs.map(tech => ({
              ...tech,
              assigneeFullName: `${tech.firstName} ${tech.lastName}`
            }))
          }
          return olp
        }) : [...state.crewWorkload.openLogpages, ...(Object.keys(currentItem).length ? [currentItem] : [])]
      }

      updatedTechWorkloadList = [...state.techWorkloadList].map(users => {
        if (unAssignedUsers.includes(users.techId)) {
          users.openTechWorkload.openLogpages = [...users.openTechWorkload.openLogpages].filter(item => item.logpageNumber !== id)
        }
        if (assignedUsersList.includes(users.techId)) {
          const _currentItem = [...state.crewWorkload['openLogpages']].find(listItem => listItem.logpageNumber === id);
          if (_currentItem) _currentItem.assignees = currentAssignees;
          const _itemInList = users.openTechWorkload.openLogpages.find(i => i.logpageNumber === id)
          users.openTechWorkload.openLogpages = _itemInList ? [...users.openTechWorkload.openLogpages].map(olp => {
            if (olp.logpageNumber === id) olp.assignees = currentAssignees;
            return olp;
          }) : [...users.openTechWorkload.openLogpages, _currentItem || currentItem]
        }
        return users
      });


      return {
        ...state,
        ...(updatedTechWorkloadList.length ? { techWorkloadList: updatedTechWorkloadList } : {}),
        ...(stateValue === 'techWorkloadList' ? {} : stateValue ? { [stateValue]: { ...state[stateValue], openLogpages: updateListItem } } : {}),
        ...(updatedCrewWorkload != null && { crewWorkload: { ...state.crewWorkload, openLogpages: updatedCrewWorkload } }),
        ...(state.myshiftActiveTab !== 'unAssignedWork' ? { unAssignedWorkload: unAssignedWorkload } : {})
      }
    }
    case `${FETCH_OPENE_NONROUTINES_LEAD_MODULE}_PENDING`: {
      return {
        ...state,
        isLoadingOpenENonRoutines: true,
        openENonRoutinesLeadModule: null
      }
    }
    case `${FETCH_OPENE_NONROUTINES_LEAD_MODULE}_FULFILLED`: {
      return {
        ...state,
        isLoadingOpenENonRoutines: false,
        openENonRoutinesLeadModule: action.payload?.getOpenENonRoutines?.aircraftNonRoutines
      }
    }
    case `${SET_PLANNING_CALL}`: {
      return {
        ...state,
        planningCall: action.payload
      }
    }
    case `${UPDATE_NR_NOTES_COUNT_MY_SHIFT}`: {
      let localCrewWorkload = JSON.parse(JSON.stringify(state.crewWorkload));
      let localTechWorkloadList = JSON.parse(JSON.stringify(state.techWorkloadList));
      let localFutureWorkload = JSON.parse(JSON.stringify(state.futureWorkload));
      let localOpenENonRoutinesLeadModule = JSON.parse(JSON.stringify(state.openENonRoutinesLeadModule));
      let localunAssignedWorkload = JSON.parse(JSON.stringify(state.unAssignedWorkload));
      const nrs = localCrewWorkload?.nonRoutines?.map(nr => {
        return updateMyShiftWorkloadNotes(nr, "nr", action.payload);
      });
      const nrs5 = localFutureWorkload?.nonRoutines?.map(nr => {
        return updateMyShiftWorkloadNotes(nr, "nr", action.payload);
      });
      const nrs3 = localOpenENonRoutinesLeadModule?.map(nr => {  
        if (nr.nonRoutineID === action.payload.nonroutineid || nr.nonRoutineID === action.payload.queryStringValues.nonroutineid) {
          nr.notesCount += 1;
          if (nr.hasOwnProperty('workStop')) {
            nr.workStop = action.payload.workStop ? action.payload.workStop : action.payload.queryStringValues.workStop ? action.payload.queryStringValues.workStop : nr.workStop;
            nr.notesActionTypeAbbreviated = action.payload.abbreviatedActionType ? action.payload.abbreviatedActionType : action.payload.queryStringValues.abbreviatedActionType ? action.payload.queryStringValues.abbreviatedActionType : ''
          }
          nr.lastNote = action.payload.lastNotes ? `${action.payload.lastNotes}${action.payload.workbin ? ` - Nonroutine has been added to workbin ${action.payload.workbin.workBinName}` : ''}` : (nr.lastNote || "");
          //nr.lastNote = action.payload.lastNotes ? action.payload.lastNotes : "";
          nr.lastNoteDetail = action.payload.lastNoteDetail ? action.payload.lastNoteDetail : "";
        }
        return nr;
      });
      const nrs4 = localunAssignedWorkload?.nonRoutines?.map(nr => {
        return updateMyShiftWorkloadNotes(nr, "nr", action.payload);
      });
      const nrs2 = localTechWorkloadList?.forEach(tech => {
        if (tech.openTechWorkload.nonRoutines) {
          tech.openTechWorkload.nonRoutines.map(nr => {
            return updateMyShiftWorkloadNotes(nr, "nr", action.payload);
          })
        }
      });

      return {
        ...state,
        crewWorkload: localCrewWorkload,
        techWorkloadList: localTechWorkloadList,
        openENonRoutinesLeadModule: localOpenENonRoutinesLeadModule,
        unAssignedWorkload: localunAssignedWorkload,
        futureWorkload: localFutureWorkload
      }
    }
    case `${UPDATE_DASH1_NOTES_COUNT_MY_SHIFT}`: {
      let localCrewWorkload = JSON.parse(JSON.stringify( state.crewWorkload ))
      let localTechWorkloadList = JSON.parse(JSON.stringify(state.techWorkloadList))
      let localunAssignedWorkload = JSON.parse(JSON.stringify(state.unAssignedWorkload ))
      let localFutureWorkload = JSON.parse(JSON.stringify(state.futureWorkload ))
      const queryStringValues = action.payload?.queryStringValues
      if (queryStringValues && queryStringValues.taskcard?.toLowerCase().includes("open") || queryStringValues.taskcard?.toLowerCase().includes("close")) {
        const tcs = localCrewWorkload.zonalCards.map(tc => {
          return updateMyShiftWorkloadNotes(tc, "zonal", action.payload);
        });
        const tcs4 = localunAssignedWorkload.zonalCards.map(tc => {
          return updateMyShiftWorkloadNotes(tc, "zonal", action.payload);
        });
        const tcs5 = localFutureWorkload.zonalCards.map(tc => {
          return updateMyShiftWorkloadNotes(tc, "zonal", action.payload);
        });
        const tcs2 = localTechWorkloadList.forEach(tech => {
          if (tech.openTechWorkload.zonalCards) {
            tech.openTechWorkload.zonalCards.map(tc => {
              return updateMyShiftWorkloadNotes(tc, "zonal", action.payload);
            })
          }
        });
      }
      else {
        const tcs = localCrewWorkload.etaskCards.map(tc => {
          return updateMyShiftWorkloadNotes(tc, "taskcard", action.payload); 
        });
        const tcs4 = localunAssignedWorkload.etaskCards.map(tc => {
          return updateMyShiftWorkloadNotes(tc, "taskcard", action.payload);
        });
        const tcs5 = localFutureWorkload.etaskCards.map(tc => {
          return updateMyShiftWorkloadNotes(tc, "taskcard", action.payload);
        });
        const tcs2 = localTechWorkloadList.forEach(tech => {
          if (tech.openTechWorkload.etaskCards) {
            tech.openTechWorkload.etaskCards.map(tc => {
              return updateMyShiftWorkloadNotes(tc, "taskcard", action.payload);
            })
          }
        });
      }

      return {
        ...state,
        crewWorkload: localCrewWorkload,
        techWorkloadList: localTechWorkloadList,
        //openENonRoutinesLeadModule: localOpenENonRoutinesLeadModule,
        unAssignedWorkload: localunAssignedWorkload,
        futureWorkload: localFutureWorkload
      }
    }
    case `RESET_MYSHIFT_DATA`: {
      return {
        ...state,
        crewWorkload: null,
        techWorkloadList: null,
        unAssignedWorkload: null,
        unAssignedWorkloadCount: 0,
        futureWorkload: null,
        openENonRoutinesLeadModule: null,
        loadModuleCrews: null,
        loadModuleCrewsDetails: null,
        selectedCrew: null
      }
    }

    default: return state;
  }
}

export default myShiftReducer