import { FETCH_PROFILE, FETCH_EXTENDED_PROFILE, SET_CURRENT_PATH, SET_LAST_REFRESHED, SET_GENERIC_ERROR, RESET_GENERIC_ERROR, SET_VIEWING_STATION, FETCH_RELEASE_NOTE, FETCH_RELEASE_VERSION } from '../actions/actionTypes';
import { getConfig } from 'utils/env-utils';

import { resetRoutineRegister } from '../actions/resetActions';
import { resetTaskcardFlags, resetSignoffUpdates, resetLinkedLogPageData } from '../actions/taskCardActions';
import { resetAccountabilityData, resetHarmonizedSearchCriteria, resetInspectionWidgetFlowFlag, setAssignedWorkToggleOn } from '../actions/registerActions';
import { resetSort, resetWarningMessage } from '../actions/archiveSearchActions';
import { resetCreateNonRoutineFlags, isApplyFiltersClickAction, resetNonRoutineRegisterData } from '../actions/nonRoutinesActions';
import { resetLoadGroups } from '../actions/visitScheduleActions';
import { resetVisitInfo } from '../actions/visitsActions';
import { resetTemplateData, resetTaskCardSearchData } from '../actions/templateActions';
import { resetWorkBins, resetWorkBinWidgetFlowFlag, resetTemplateWorkbin } from '../actions/WorkBinsActions';
import { resetSearchWorkload } from '../actions/assignCrewActions';
import queryString from 'query-string';
import { resetMyShiftData } from 'store/actions/myShiftActions';
import { fetchVisitInfo } from 'store/actions/visitsActions';

const initialState = {
    userProfile: null,
    userProfileExtended: null,
    viewingStation: "",
    currentPath: "",
    lastRefreshed: null,
    subHeaderDetails: null,
    showGenericError: false,
    genricError: "",
    isLoadingProfile: false,
    navData: null,
    isLoadingReleaseNote: true
}
const profileReducer = (state = initialState, action) => {
    const BaseDashboardPath = { label: "Base dashboard", path: "/dashboard" };
    const VisitDashboardPath = { label: "Visit dashboard", path: "/dashboard/VisitInfo" };
    const RoutineRegisterPath = { label: "Workload", path: "/dashboard/RoutineRegister" };
    const TaskCardPath = { label: "Task card", path: "/dashboard/TaskCard" };
    const CardSearchPath = { label: "Card search", path: "/cardSearch" };
    const ArchiveCardPath = { label: "Archived card search", path: "/cardSearch/ArchiveCardSearch" };
    const TaskCardSearchPath = { label: "Task Card", path: "/cardSearch/ArchiveCardSearch/TaskCard" };
    const MasterCardSearchPath = { label: "Master Card", path: "/cardSearch/MasterCardSearch" };
    //unused path
    //const NonRoutineRegisterPath = { label: "Non-Routines", path: "/dashboard/NonRoutineRegister" };
    const AccountabilityPath = { label: "Accountability report", path: "/dashboard/Accountability" };
    const CreateNonRoutinePath = { label: "Create Non-Routine", path: "/dashboard/CreateNonRoutine" };
    const ViewNonRoutinePath = { label: "View Non-Routine", path: "/dashboard/ViewNonRoutine" };
    const ViewNonRoutineTaskCardPath = { label: "View Non-Routine", path: "/dashboard/TaskCard/ViewNonRoutine" };
    const LineRoutineRegisterPath = { label: "Workload", path: "/dashboard/line/RoutineRegister" };
    const MyWork = { label: "My work", path: `${getConfig('AMTS_MYWORK_URL')}` };
    const LinkedNonRoutines = { label: "Non-Routine Register", path: "/dashboard/TaskCard/LinkedNonRoutines" };
    const ZonalCardLinkedNonRoutines = { label: "Non-Routine Register", path: "/dashboard/ZonalCard/LinkedNonRoutines" };
    const ViewOnlyTaskCard = { label: "Task card", path: "/dashboard/line/viewonly/TaskCard" };
    const VisitSchedulingGroupsSummaryPath = { label: "Groups", path: "/dashboard/Groups" };
    const VisitSchedulingGroupingAgentPath = { label: "Grouping Agent", path: "/dashboard/GroupingAgent" }
    const VisitSchedulingTimelinePath = { label: "Timeline", path: "/dashboard/Timeline" }
    const LinkedLogPages = { label: "Log pages", path: "/dashboard/TaskCard/LinkedLogpages" }
    const DuplicateTaskCardsPath = { label: "Duplicate taskcards", path: "/dashboard/DuplicateTaskCards" }
    const PartsAndToolsSummaryPath = { label: "Parts and tools summary", path: "/dashboard/PartsAndToolsSummary" }
    const GroupingHistoryPath = { label: "History", path: "/dashboard/GroupSummary/History" }
    const TaskCardNRPath = { label: "Task Card", path: "/dashboard/ViewNonRoutine/TaskCard" }
    const TaskCardLinePath = { label: "Task Card", path: "/dashboard/line/TaskCard" }
    const SearchWorkloadPath = { label: "Search Workload", path: "/dashboard/SearchWorkload" };
    const ViewNonRoutineSearchWorkloadPath = { label: "View Non-Routine", path: "/dashboard/SearchWorkload/ViewNonRoutine" };
    const ManageViewRoutinePath = { label: "Manage Non-Routine", path: "/dashboard/ViewNonRoutine/ManageNonRoutine" }
    const SearchWorkloadTaskCardPath = { label: "Task card", path: "/dashboard/SearchWorkload/TaskCard" };
    const LineSearchWorkloadPath = { label: "Search Workload", path: "/dashboard/line/SearchWorkload" };
    const LineSearchWorkloadTaskCardPath = { label: "Task card", path: "/dashboard/line/SearchWorkload/TaskCard" };
    const TemplatesPath = { label: "Templates", path: "/dashboard/Templates" };
    const TaskCardDetailsPath = { label: "Task card details", path: "/dashboard/Templates/TemplateTaskCardDetails" };
    const TemplateDetailsPath = { label: "Template details", path: "/dashboard/Templates/TemplateDetails" };
    const TemplateMilestonesPath = { label: "Template milestones", path: "/dashboard/Templates/TemplateMilestones" };
    const TemplateTaskCardsPath = { label: "Template taskcards", path: "/dashboard/Templates/TemplateTaskCards" };
    const PickTemplatesPath = { label: "Templates", path: "/dashboard/VisitInfo/Templates" };
    const ApplyTemplatePath = { label: "Template details", path: "/dashboard/VisitInfo/Templates/TemplateDetails" };
    const TemplateTimelinePath = { label: "Timeline", path: "/dashboard/Templates/TemplateTimeline" };
    const TemplateGroupingPath = { label: "Grouping agent", path: "/dashboard/Templates/TemplateGrouping" };
    const VisitTaskCardsPath = { label: "Visit taskcards", path: "/dashboard/VisitInfo/VisitTaskCards" };
    const VisitTaskCardDetailsPath = { label: "Task card details", path: "/dashboard/VisitInfo/VisitTaskCardDetails" };
    const VisitMilestonesPath = { label: "Visit milestones", path: "/dashboard/VisitInfo/VisitMilestones" };
    const VisitMilestonesOverrideHistoryPath = { label: "Milestones history", path: "/dashboard/VisitInfo/VisitMilestones/History" }
    const VisitTimelinePath = { label: "Timeline", path: "/dashboard/VisitInfo/VisitTimeline" };
    const VisitTemplateTaskCardDetailsPath = { label: "", path: "/dashboard/VisitInfo/TaskCard" };
    const ViewOnlyMasterCard = { label: "", path: "/dashboard/viewonly/TaskCard" };
    const ViewOnlyNR = { label: "", path: "/dashboard/TemplateView/ViewNonRoutine" };
    const TemplateMilestoneGroupingPath = { label: "Grouping agent", path: "/dashboard/Templates/TemplateMilestones/TemplateGrouping" };
    const VisitMilestoneGroupingPath = { label: "Grouping agent", path: "/dashboard/VisitInfo/VisitMilestones/GroupingAgent" }
    const TemplateGoupingTimelinePath = { label: "Grouping agent", path: "/dashboard/Templates/GroupingAgent" }
    const AssignCrewPath = { label: "Assign crew", path: "/dashboard/AssignCrew" };
    const AssignCrewLegacyPath = { label: "Assign crew", path: "/dashboard/AssignCrewLegacy" };
    const GroupingAgentPath = { label: "Grouping agent", path: "/dashboard/GroupingAgent" };
    const ZonalCardPath = { label: "Zonal card", path: "/dashboard/ZonalCard" };
    const VisitZonalCardPath = { label: "", path: "/dashboard/VisitInfo/ZonalCard" };
    const SearchWorkloadZonalCardPath = { label: "Task card", path: "/dashboard/SearchWorkload/ZonalCard" };
    const TaskAuditPath = { label: "Task audit", path: "/dashboard/TaskAudit" };
    const TaskAuditActivityLogPath = { label: "Task audit activity log", path: "/dashboard/TaskAudit/ActivityLog" };
    const ViewOnlyZonalCard = { label: "Zonal card", path: "/dashboard/line/viewonly/ZonalCard" };
    const WorkBinsPath = { label: "Work bins", path: "/dashboard/WorkBins" };
    const AddWorkloadPath = { label: "Add workload", path: "/dashboard/WorkBins/AddWorkload" };
    const TemplatesWorkBinsPath = { label: "Work bins", path: "/dashboard/Templates/WorkBins" };
    const TemplatesAddWorkloadPath = { label: "Add workload", path: "/dashboard/Templates/WorkBins/AddWorkload" };
    const ViewNonRoutineZonalCardPath = { label: "View Non-Routine", path: "/dashboard/ZonalCard/ViewNonRoutine" };
    const BaselineReportPath = { label: "Baseline Report", path: "/dashboard/Baseline/BaselineReport" };
    const LinkedEAsPath = { label: "Linked EAs", path: "/dashboard/TaskCard/LinkedEAs" };
    const MyShiftPath = { label: "My Shift", path: "/dashboard/MyShift" };
    const ShiftHandOverReportPath = { label: "Shift Handover Report", path: "/dashboard/ShiftHandOverReport" };
    const AircraftStatusPath = { label: "Aircraft Status", path: "/dashboard/AircraftStatus" };

    const amtsURL = `${getConfig('AMTS_URL')}`;
    const poURL = `${getConfig('PO_URL')}`;
    const udocsURL = `${getConfig('UDOCS_URL')}`;
    const techopsURL = `${getConfig('TECHOPS_URL')}`;
    const mapsURL = `${getConfig('MAPS_URL')}`;
    const eLottoURL = `${getConfig('ELOTTO_URL')}`;
    const webtechURL = `${getConfig('WEBTECH_URL')}`;
    const toolboxURL = `${getConfig('TOOLBOX_URL')}`;
    const nhaReportUrl = `${getConfig('NHA_REPORT_URL')}`;
    const stationplanURL = `${getConfig('STATIONPLAN_URL')}`;
    let currentUrl = window.location.search.toLowerCase();
    const queryStringValues = queryString.parse(currentUrl);
    const tcmUrl = `${getConfig('TCM_URL')}`;
    
    //need to get rid of this
    const visitInfoCall = true

    const navData = [
        {
            navLabel: "Reports", links: [
                { label: "Accountability report", path: AccountabilityPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "Parts and Tools Summary", path: PartsAndToolsSummaryPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "Duplicate taskcards", path: DuplicateTaskCardsPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "NHA report", path: `${nhaReportUrl}?tailNumber=${queryString.parse(currentUrl).aircraftnumber}&station=${queryString.parse(currentUrl).station}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "Task audit", path: TaskAuditPath.path, isExternal: false, isArchivedVisible: false, isFutureVisible: false },
                { label: "Shift Handover Report", path: ShiftHandOverReportPath.path, isExternal: false, isArchivedVisible: false, isFutureVisible: false },
                { label: "Aircraft Status", path: AircraftStatusPath.path, isExternal: false, isArchivedVisible: false, isFutureVisible: false }],
            validateVisit: false
        },
        {
            navLabel: "Workload", links: [
                { label: "View Workload", path: RoutineRegisterPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "Search Workload", path: SearchWorkloadPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "Card Search", path: CardSearchPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "My Shift", path: MyShiftPath.path, isExternal: false, isArchivedVisible: false, isFutureVisible: false }],
            validateVisit: false
        },
        {
            navLabel: "Visit", links: [
                { label: "Taskcard search", path: VisitTaskCardsPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "Grouping agent", path: VisitSchedulingGroupingAgentPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true },
                { label: "Assign crew", path: AssignCrewPath.path, isExternal: false, isArchivedVisible: false, isFutureVisible: false },
                { label: "Work bins", path: AssignCrewPath.path, isExternal: false, isArchivedVisible: true, isFutureVisible: true }],
            validateVisit: true
        },
        {
            navLabel: "External links", links: [
                { label: "My Crew (AMTS)", path: `${amtsURL}/my-crew`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "Station Plan (AMTS)", path: `${stationplanURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "AMTS Home", path: `${amtsURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "UDOCS", path: `${udocsURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "TECH OPS Portal", path: `${techopsURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "Parts Ordering", path: `${poURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "Terminal View", path: `${mapsURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "eLOTO", path: `${eLottoURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "WEBTECH", path: `${webtechURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "Toolbox Library", path: `${toolboxURL}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true },
                { label: "TCM", path: `${tcmUrl}?AppName=BAM&TailNumber=${queryString.parse(currentUrl).aircraftnumber}`, isExternal: true, isArchivedVisible: true, isFutureVisible: true }],
            validateVisit: false
        }];

    const pageSubHeaderDetails = {
        "/": { BreadcrumbsInfo: null, Title: "Base dashboard", VisitDetailsType: "", resetFunctions: [resetLoadGroups, resetVisitInfo, resetTaskCardSearchData, resetTemplateData, resetMyShiftData] },
            "/cardSearch": { BreadcrumbsInfo: [BaseDashboardPath, CardSearchPath], Title: "", VisitDetailsType: "", resetFunctions: [resetSort, resetTaskcardFlags] },       
            "/dashboard": { BreadcrumbsInfo: null, Title: "Base dashboard", VisitDetailsType: "", resetFunctions: [resetLoadGroups, resetVisitInfo, resetTaskCardSearchData, resetTemplateData, setAssignedWorkToggleOn, resetRoutineRegister, resetMyShiftData] },       
            "/dashboard/VisitInfo": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath], Title: "Visit dashboard", VisitDetailsType: "", resetFunctions: [resetRoutineRegister, resetTaskCardSearchData, resetWorkBins, resetWorkBinWidgetFlowFlag, resetInspectionWidgetFlowFlag, resetSearchWorkload, setAssignedWorkToggleOn], initFunctions:[visitInfoCall] },       
            "/dashboard/RoutineRegister": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath], Title: "Workload", VisitDetailsType: "VisitInfo", resetFunctions: [resetTaskcardFlags, resetAccountabilityData, resetLoadGroups, resetHarmonizedSearchCriteria, resetVisitInfo], initFunctions:[visitInfoCall] },       
            "/dashboard/TaskCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskCardPath], Title: "Task Card", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister, resetWarningMessage, resetNonRoutineRegisterData, resetSignoffUpdates, resetLinkedLogPageData], initFunctions:[visitInfoCall] },       
            "/cardSearch/ArchiveCardSearch": { BreadcrumbsInfo: [BaseDashboardPath, CardSearchPath, ArchiveCardPath], Title: "Archived card search", VisitDetailsType: "" },        
            "/cardSearch/ArchiveCardSearch/TaskCard": { BreadcrumbsInfo: [BaseDashboardPath, CardSearchPath, ArchiveCardPath, TaskCardSearchPath], Title: "Task Card", VisitDetailsType: "" },       
            "/cardSearch/MasterCardSearch": { BreadcrumbsInfo: [BaseDashboardPath, CardSearchPath, MasterCardSearchPath], Title: "Master Card", VisitDetailsType: "" },       
            "/dashboard/Accountability": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, AccountabilityPath], Title: "Accountability report", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister], initFunctions:[visitInfoCall] },
            //can be removed
            "/dashboard/Accountability/TaskCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, AccountabilityPath, TaskCardPath], Title: "Task Card", VisitDetailsType: "", initFunctions:[visitInfoCall] },        
            "/dashboard/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "", resetFunctions: [resetRoutineRegister] },       
            "/dashboard/ViewNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ViewNonRoutinePath], Title: "View Non-Routine", VisitDetailsType: "VisitInfo", resetFunctions: [resetNonRoutineRegisterData, resetRoutineRegister],initFunctions:[visitInfoCall] },       
            "/dashboard/NonRoutineActions": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ViewNonRoutinePath], Title: " Non-Routine", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister],initFunctions:[visitInfoCall] },        
            "/dashboard/ViewNonRoutine/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ViewNonRoutinePath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "VisitInfo" , initFunctions:[visitInfoCall] },        
            "/dashboard/ViewNonRoutine/ManageNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ViewNonRoutinePath, ManageViewRoutinePath], Title: "Manage Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },
            "/dashboard/TaskCard/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskCardPath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
        "/dashboard/line/RoutineRegister": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath], Title: "Workload", VisitDetailsType: "", resetFunctions: [resetTaskcardFlags, resetHarmonizedSearchCriteria] },        
        "/dashboard/line/TaskCard": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath, TaskCardLinePath], Title: "Task Card", VisitDetailsType: "", resetFunctions: [resetRoutineRegister] },
            "/dashboard/TaskCard/LinkedNonRoutines": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskCardPath, LinkedNonRoutines], Title: "Linked Non-Routines", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
            "/dashboard/TaskCard/ViewNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskCardPath, LinkedNonRoutines, ViewNonRoutineTaskCardPath], Title: "View Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        "/dashboard/line/viewonly/TaskCard": { BreadcrumbsInfo: [ViewOnlyTaskCard], Title: "Task Card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        //Obsolete 
        "/dashboard/Groups": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingGroupsSummaryPath], Title: "Groups", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },       
            "/dashboard/GroupingAgent": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingGroupingAgentPath], Title: "Grouping agent", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/Timeline": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingTimelinePath], Title: "Timeline", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },        
            "/dashboard/TaskCard/LinkedLogpages": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskCardPath, LinkedLogPages], Title: "Log pages", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
            "/dashboard/DuplicateTaskCards": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, DuplicateTaskCardsPath], Title: "Duplicate taskcards", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
            //can be removed
            "/dashboard/DuplicateTaskCards/TaskCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, DuplicateTaskCardsPath, TaskCardPath], Title: "Task Card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            "/dashboard/PartsAndToolsSummary": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, PartsAndToolsSummaryPath], Title: "Parts and tools summary", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/GroupsSummary/GroupingAgent": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingGroupsSummaryPath, VisitSchedulingGroupingAgentPath], Title: "Grouping agent", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/GroupSummary/History": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingGroupsSummaryPath, GroupingHistoryPath], Title: "History", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/Timeline/History": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingTimelinePath, GroupingHistoryPath], Title: "History", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },       
            "/dashboard/Timeline/GroupingAgent": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitSchedulingTimelinePath, VisitSchedulingGroupingAgentPath], Title: "Visit grouping agent", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },        
        "/dashboard/ViewNonRoutine/TaskCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ViewNonRoutinePath, TaskCardNRPath], Title: "Task Card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
        "/dashboard/line/TaskCard/LinkedLogpages": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath, TaskCardLinePath, LinkedLogPages], Title: "Log pages", VisitDetailsType: "" },       
            "/dashboard/SearchWorkload": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath], Title: "Search Workload", VisitDetailsType: "VisitInfo", resetFunctions: [resetTaskcardFlags], initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/SearchWorkload/ViewNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, ViewNonRoutineSearchWorkloadPath], Title: "View Non-Routine", VisitDetailsType: "VisitInfo" },        
        //Obsolete
        "/dashboard/SearchWorkload/ViewNonRoutine/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, ViewNonRoutineSearchWorkloadPath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/SearchWorkload/NonRoutineActions": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, ViewNonRoutineSearchWorkloadPath], Title: " Non-Routine", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister], initFunctions:[visitInfoCall] },       
            //can be removed    
            "/dashboard/SearchWorkload/TaskCard/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, SearchWorkloadTaskCardPath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            //can be removed
            "/dashboard/SearchWorkload/TaskCard/LinkedLogpages": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, SearchWorkloadTaskCardPath, LinkedLogPages], Title: "Log pages", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            //can be removed
            "/dashboard/SearchWorkload/TaskCard/LinkedNonRoutines": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, SearchWorkloadTaskCardPath, LinkedNonRoutines], Title: "Linked Non-Routines", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            //can be removed    
            "/dashboard/SearchWorkload/TaskCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, SearchWorkloadTaskCardPath], Title: "Task Card", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister, resetWarningMessage, resetNonRoutineRegisterData, resetSignoffUpdates, resetLinkedLogPageData], initFunctions:[visitInfoCall] },        
        "/dashboard/line/SearchWorkload": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath, LineSearchWorkloadPath], Title: "Search Workload", VisitDetailsType: "", resetFunctions: [resetTaskcardFlags] },        
        "/dashboard/line/SearchWorkload/TaskCard": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath, LineSearchWorkloadPath, LineSearchWorkloadTaskCardPath], Title: "Task Card", VisitDetailsType: "", resetFunctions: [resetRoutineRegister] },       
        "/dashboard/line/SearchWorkload/TaskCard/LinkedLogpages": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath, LineSearchWorkloadPath, LineSearchWorkloadTaskCardPath, LinkedLogPages], Title: "Log pages", VisitDetailsType: "" },       
            "/dashboard/Templates": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath], Title: "Templates", VisitDetailsType: "" },        
            "/dashboard/Templates/TemplateTaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "" },       
            "/dashboard/Templates/TemplateDetails": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath], Title: "Template details", VisitDetailsType: "", resetFunctions: [resetTaskCardSearchData, resetTemplateWorkbin] },       
            "/dashboard/Templates/TemplateMilestones": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateMilestonesPath], Title: "Template milestones", VisitDetailsType: "" },       
            "/dashboard/Templates/TemplateTaskCards": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateTaskCardsPath], Title: "Template taskcards", VisitDetailsType: "" },        
            "/dashboard/VisitInfo/Templates": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, PickTemplatesPath], Title: "Templates", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall] },       
            "/dashboard/VisitInfo/Templates/TemplateDetails": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, PickTemplatesPath, ApplyTemplatePath], Title: "Apply template", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall] },       
            "/dashboard/Templates/TemplateTaskCards/TemplateTaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateTaskCardsPath, TaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "" },       
            "/dashboard/Templates/TemplateTimeline": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateTimelinePath], Title: "Timeline", VisitDetailsType: "" },       
            "/dashboard/Templates/TemplateGrouping": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateGroupingPath], Title: "Template grouping agent", VisitDetailsType: "" },        
            "/dashboard/VisitInfo/VisitTaskCards": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitTaskCardsPath], Title: "Visit taskcards", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall] },        
        "/dashboard/VisitInfo/VisitTaskCards/VisitTaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitTaskCardsPath, VisitTaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/VisitInfo/VisitTaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitTaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
            "/dashboard/VisitInfo/VisitMilestones": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitMilestonesPath], Title: "Visit milestones", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },        
            "/dashboard/VisitInfo/VisitMilestones/History": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitMilestonesPath, VisitMilestonesOverrideHistoryPath], Title: "Milestones history", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },       
            "/dashboard/VisitInfo/VisitTimeline": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitTimelinePath], Title: "Timeline", VisitDetailsType: "VisitSchedule", initFunctions:[visitInfoCall] },        
        //re check
        "/dashboard/VisitInfo/TaskCard": { BreadcrumbsInfo: [VisitTemplateTaskCardDetailsPath], Title: "Task card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            "/dashboard/viewonly/TaskCard": { BreadcrumbsInfo: [ViewOnlyMasterCard], Title: "Task card", VisitDetailsType: "" },       
        //re check
        "/dashboard/TemplateView/ViewNonRoutine": { BreadcrumbsInfo: [ViewOnlyNR], Title: "View Non-Routine", VisitDetailsType: "" },        
        //Obsolete
        "/dashboard/Templates/TemplateMilestones/TemplateGrouping": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateMilestonesPath, TemplateMilestoneGroupingPath], Title: "Template grouping agent", VisitDetailsType: "" },        
            "/dashboard/VisitInfo/VisitMilestones/GroupingAgent": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitMilestonesPath, VisitMilestoneGroupingPath], Title: "Visit grouping agent", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            "/dashboard/Templates/GroupingAgent": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateTimelinePath, TemplateGoupingTimelinePath], Title: "Template grouping agent", VisitDetailsType: "" },       
            "/dashboard/AssignCrew": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, AssignCrewPath], Title: "Assign crew", VisitDetailsType: "VisitInfo" },    
            "/dashboard/Templates/AssignCrew": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, AssignCrewPath], Title: "Work Bins ", VisitDetailsType: "" },          
        //Obsolete
        "/dashboard/AssignCrewLegacy": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, AssignCrewLegacyPath], Title: "Assign crew legacy", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/AssignCrew/TaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, AssignCrewPath, VisitTaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/GroupingAgent/VisitTaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, GroupingAgentPath, VisitTaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
            "/dashboard/ZonalCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ZonalCardPath], Title: "Zonal card", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister, resetWarningMessage, resetSignoffUpdates], initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/GroupingAgent/TemplateTaskCardDetails": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplateGroupingPath, TaskCardDetailsPath], Title: "Task card details", VisitDetailsType: "" },        
        //Obsolete
        "/dashboard/VisitInfo/ZonalCard": { BreadcrumbsInfo: [VisitZonalCardPath], Title: "Zonal card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },               
            //can be removed
            "/dashboard/SearchWorkload/ZonalCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, SearchWorkloadZonalCardPath], Title: "Zonal card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            "/dashboard/TaskAudit": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskAuditPath], Title: "Task audit", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister], initFunctions:[visitInfoCall] },       
        "/dashboard/line/viewonly/ZonalCard": { BreadcrumbsInfo: [ViewOnlyZonalCard], Title: "Zonal Card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
            "/dashboard/TaskAudit/ActivityLog": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskAuditPath, TaskAuditActivityLogPath], Title: "Task audit activity log", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister], initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/WorkBins": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, WorkBinsPath], Title: "Work bins", VisitDetailsType: "VisitInfo", resetFunctions: [resetSearchWorkload], initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/WorkBins/AddWorkload": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, WorkBinsPath, AddWorkloadPath], Title: "Add workload", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall] },
        //Obsolete
        "/dashboard/Templates/WorkBins": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplatesWorkBinsPath], Title: "Work bins", VisitDetailsType: "", resetFunctions: [resetSearchWorkload] },       
        //Obsolete
        "/dashboard/Templates/WorkBins/AddWorkload": { BreadcrumbsInfo: [BaseDashboardPath, TemplatesPath, TemplateDetailsPath, TemplatesWorkBinsPath, TemplatesAddWorkloadPath], Title: "Add workload", VisitDetailsType: "", resetFunctions: [] },       
            "/dashboard/ZonalCard/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ZonalCardPath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/ZonalCard/ViewNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ZonalCardPath, ZonalCardLinkedNonRoutines, ViewNonRoutineZonalCardPath], Title: "View Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            "/dashboard/ZonalCard/LinkedNonRoutines": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ZonalCardPath, ZonalCardLinkedNonRoutines], Title: "Linked Non-Routines", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
        //Obsolete
        "/dashboard/SearchWorkload/ZonalCard/CreateNonRoutine": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, SearchWorkloadPath, SearchWorkloadZonalCardPath, CreateNonRoutinePath], Title: "Create Non-Routine", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        //Obsolete
        "/dashboard/VisitInfo/VisitTaskCardDetails/ZonalCard": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, VisitTaskCardDetailsPath], Title: "Zonal Card Details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },        
            "/dashboard/Standalone/TaskCardDetails": { BreadcrumbsInfo: [], Title: "Task card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },
            "/dashboard/Standalone/TaskCard": { BreadcrumbsInfo: [], Title: "Task card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },
            "/dashboard/Standalone/ZonalCardDetails": { BreadcrumbsInfo: [], Title: "Zonal card details", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },
            "/dashboard/Standalone/ZonalCard": { BreadcrumbsInfo: [], Title: "Zonal card", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },
        //have to reset functions in below baseline
            "/dashboard/baseline/baselineReport": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, BaselineReportPath], Title: "Baseline Report", VisitDetailsType: "VisitInfo", resetFunctions: [resetRoutineRegister], initFunctions:[visitInfoCall] },        
            "/dashboard/TaskCard/LinkedEAs": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, TaskCardPath, LinkedEAsPath], Title: "Linked EAs", VisitDetailsType: "VisitInfo", initFunctions:[visitInfoCall] },       
        "/dashboard/line/TaskCard/LinkedEAs": { BreadcrumbsInfo: [MyWork, LineRoutineRegisterPath, TaskCardLinePath, LinkedEAsPath], Title: "Linked EAs", VisitDetailsType: "" },        
            "/dashboard/MyShift": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, MyShiftPath], Title: "My Shift", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall]},        
            "/dashboard/ShiftHandOverReport": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, ShiftHandOverReportPath], Title: "Shift Handover Report", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall] },
            "/dashboard/AircraftStatus": { BreadcrumbsInfo: [BaseDashboardPath, VisitDashboardPath, RoutineRegisterPath, AircraftStatusPath], Title: "Aircraft Status", VisitDetailsType: "VisitInfo", resetFunctions: [], initFunctions:[visitInfoCall] },
    };
    switch (action.type) {
        case `${FETCH_PROFILE}_FULFILLED`: {
            let profile = action.payload.data.profile;
            if (window.location.href.indexOf('localhost') !== -1 || profile.userId === null) {
                profile = { firstName: 'Harsha', lastName: 'Chinthala', station: 'SMB', userId: 'V795013', isBaseOverride: null, role: 'SUPV' };
            }
            return {
                ...state
            }
        }
        case `${FETCH_EXTENDED_PROFILE}_PENDING`: {
            return {
                ...state,
                isLoadingProfile: true
            }
        }
        case `${FETCH_EXTENDED_PROFILE}_FULFILLED`: {
            return {
                ...state,
                userProfile: action.payload.getProfile,
                isLoadingProfile: false
            }
        }
        case `${FETCH_EXTENDED_PROFILE}`: {

            return {

                ...state,

                userProfile: {

                    "userId": "N079848",

                    "firstName": "Nikhil",

                    "lastName": "Bhomi",

                    "station": "IHC",

                    "jobRole": "MXSUPV",

                    "originalEtaskRole": "MXSUPV",

                    "isBaseOverride": true,

                    "userActions": [

                        "[Clear, True]",

                        "[Create, True]",

                        "[Update, True]",

                        "[Defer, True]",

                        "[Carry, True]",

                        "[Void, True]",

                        "[Inspector, True]",

                        "[IsMvt, True]",

                        "[IsRII, True]",

                        "[IsIDT, True]"

                    ],

                    "isBaseFlow": true,

                    "isProductionController": true,

                    "isVendorStation": false,

                    "lTerm": "IHCFT1VD"

                },

                isLoadingProfile: false

            }

        }
        case `${FETCH_RELEASE_NOTE}_PENDING`: {
            return {
                ...state,
                isLoadingReleaseNote: true
            }
        }
        case `${FETCH_RELEASE_NOTE}_FULFILLED`: {
            return {
                ...state,
                releaseNote: action.payload.getReleaseNotesDetails,
                isLoadingReleaseNote: false
            }
        }
        case `${FETCH_RELEASE_VERSION}_PENDING`: {
            return {
                ...state,
                isLoadingReleaseVersion: true
            }
        }
        case `${FETCH_RELEASE_VERSION}_FULFILLED`: {
            return {
                ...state,
                getReleaseVersion: action.payload.getReleaseVersion,
                isLoadingReleaseVersion: false
            }
        }
        case `${SET_CURRENT_PATH}`: {
            const currentPath = action.payload.currentPath === "/" ? action.payload.currentPath : action.payload.currentPath.replace(/^(.*)\/$/, '$1');
            let subheaderDetails = pageSubHeaderDetails[currentPath];
            if (subheaderDetails !== undefined && subheaderDetails !== null) {
                const title = action.payload.currentPath.indexOf("NonRoutineActions") !== -1 ? action.payload.nonRoutineAction + subheaderDetails.Title : subheaderDetails.Title;
                subheaderDetails.Title = title;
                if (action.payload.currentPath.indexOf("NonRoutineActions") !== -1)
                    subheaderDetails.BreadcrumbsInfo.push({ label: title, path: "/dashboard/NonRoutineActions" })
            }
            return {
                ...state,
                currentPath: action.payload.currentPath,
                subHeaderDetails: subheaderDetails,
                navData: navData
            }
        }
        case `${SET_LAST_REFRESHED}`: {
            return {
                ...state,
                lastRefreshed: action.payload
            }
        }
        case `${SET_GENERIC_ERROR}`: {
            return {
                ...state,
                showGenericError: true,
                genricError: action.payload
            }
        }
        case `${RESET_GENERIC_ERROR}`: {
            return {
                ...state,
                showGenericError: false,
                genricError: ""
            }
        }
        case `${SET_VIEWING_STATION}`: {
            return {
                ...state,
                viewingStation: action.payload
            }
        }
        default: return state;
    }
}

export default profileReducer;