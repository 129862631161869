import {
  FETCH_TASKCARD_DATA, SET_LAST_REFRESHED, UPDATE_SIGNOFF_ARRAY, UPDATE_SIGNOFF_VALIDATIONS,
  SIGNOFF_BUTTON_CLICK, SET_GENERIC_ERROR, ADD_NAV_POINTER, SET_ACTIVE_NAV_ITEM, SET_NOTIFICATION_MESSAGE,
  RESET_TASKCARD_DATA, RESET_SIGNOFF_ERROR_MESSAGE, TOGGLE_BULK_SIGNOFF_MODAL, RESET_SIGNOFF_ARRAY,
  TOGGLE_SIGNOFF_MODAL, FETCH_ILLUSTRATIONS_IMAGES, RESET_ILLUSTRATIONS_IMAGES, FETCH_VISIT_INFO,
  FETCH_MASTERCARD_DATA, CARD_SEARCH_TYPE, SET_AIRCRAFT_INFO, FETCH_LINKED_LOGPAGES, RESET_LINKED_LOGPAGE_DATA,
  FETCH_INDUCTED_AIRCRAFT_INFO, RESET_INDUCTED_AIRCRAFT_INFO, GET_DASH1_NOTES, SAVE_DASH1_NOTES,
  UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY, UPDATE_DASH1_NOTES_COUNT, RESET_DASH1_NOTES, FETCH_LINKED_EA,
  FETCH_FLIFO, UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY, UPDATE_DASH1_NOTES_COUNT_MY_SHIFT, UPDATE_NR_NOTES_COUNT_MY_SHIFT, SAVE_SHIFT_SUMMAR_NOTES_LOCALLY
} from './actionTypes';
import { trackPromise } from 'react-promise-tracker';
import * as taskCardQueries from '../queries/TaskCardQueries';
import * as visitQueries from '../queries/VisitQueries';
import * as Queries from '../queries/RegisterQueries';
//import * as ShiftHandoverQueries from '../queries/RegisterQueries';
import { executeQuery } from '../helper';
import { numberLiteral } from '@babel/types';

export const fetchTaskCardData = (dash1, visitInfo, profile, isPastOrFutureVisit, isArchived, isViewOnlyMode, isLine) => {
  const variable = {
    "routinesContent": {
      "assignedStation": visitInfo ? visitInfo.station : dash1.station,
      "tailNumber": visitInfo ? visitInfo.aircraftNumber : dash1.tailNumber,
      "flightNumber": dash1.flightNumber ? dash1.flightNumber : "0000",
      "forecastSerialNumber": dash1.serialNumber,
      "workPackageSerialNumber": dash1.workControlNumber,
      "jobNumber": dash1.dash8Number ? dash1.dash8Number : dash1.dash8,
      "cardNumber": dash1.dash1Number ? dash1.dash1Number : dash1.cardNumber,
      "employeeID": profile.userId,
      "carddoctype": dash1.carddoctype ? dash1.carddoctype : dash1.cardType,
      "cardId": dash1.cardId ? dash1.cardId : "",
      "rootId": dash1.rootId ? dash1.rootId : "",
      "scheduledArrivalDate": visitInfo ? visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate : dash1.scheduledArrivalDate,
      "isIDT": (profile.userActions.includes("[IsIDT, True]")),
      "isRII": (profile.userActions.includes("[IsRII, True]")),
      "isMVT": (profile.userActions.includes("[IsMvt, True]")),
      "originalEtaskJobRole": profile.originalEtaskRole,
      "jobRole": profile.jobRole,
      "isPastOrFutureVisit": isPastOrFutureVisit,
      "isArchivedCard": isArchived,
      "isViewOnlyMode": isViewOnlyMode,
      "masterId": dash1.masterId,
      "isInducted": false,
      "showNonRoutines": false,
      "departureDate": visitInfo ? visitInfo.plannedETRDate : dash1.departureDate === "null" ? null : dash1.departureDate,
      "optimizedGroupID": dash1.optimizedGroupID,
      "optimizedDash8sList": dash1.optimizedDash8sList,
      "isOptimized": dash1.isOptimized,
      "dash1Status": dash1.dash1Status
    }
  }

  return (dispatch, getState) => {
    if (isLine) {
      const aircraftInfo =
      {
        aircraftNumber: dash1.tailNumber,
        arrivalDate: dash1.scheduledArrivalDate,
        departureDate: dash1.departureDate,
        acControlId: null,
        inFlightNumber: "0000",
        isPastOrFutureVisit: false,
        isArchived: false,
        isFuture: false,
        showNonRoutines: false
      };
      dispatch({
        type: SET_AIRCRAFT_INFO,
        payload: aircraftInfo
      });
    }
    dispatch({
      type: FETCH_TASKCARD_DATA,
      payload: executeQuery(taskCardQueries.fetchTaskCardData(), variable, getState(), 'RoutineContent')
    }).then(resp => {
      if (resp !== null && resp.routineContent !== null) {
        dispatch({
          type: SET_LAST_REFRESHED,
          payload: Date.now()
        })
      }
    })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}

export const fetchTaskCardDataWithInductedInfo = (dash1, visitInfo, profile, isPastOrFutureVisit, isArchived, isViewOnlyMode, isLine) => {
  const schedArrDate = visitInfo ? visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate : dash1.scheduledArrivalDate;
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_INDUCTED_AIRCRAFT_INFO,
      payload: executeQuery(Queries.GetInductedAircraftData(visitInfo ? visitInfo.station : dash1.station, visitInfo ? visitInfo.aircraftNumber : dash1.tailNumber, schedArrDate), null, getState(), 'Visit')
    })
      .then(resp => {
        if (resp.value.getInductedACData && !resp.value.getInductedACData.isInducted) {
          dispatch(fetchTaskCardData(dash1, visitInfo, profile, isPastOrFutureVisit, isArchived, isViewOnlyMode, isLine));
        }
      })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}

export const fetchMasterCardData = (masterCardId, isDraft) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_MASTERCARD_DATA,
      payload: executeQuery(taskCardQueries.fetchMasterCardData(masterCardId, isDraft), null, getState(), 'Visit')
    }).then(resp => {
      if (resp !== null && resp.masterSearch !== null) {
        dispatch({
          type: SET_LAST_REFRESHED,
          payload: Date.now()
        })
      }
    })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}


export const fetchIllustrationImage = (id, fileName, masterId) => {
  const variable = {
    "illustrations":
    {
      "id": id,
      "masterId": masterId,
      "imageName": fileName
    }
  }

  return dispatch => {
    dispatch({
      type: FETCH_ILLUSTRATIONS_IMAGES,
      payload: fileName.toLowerCase().endsWith("pdf") ? trackPromise(executeQuery(taskCardQueries.fetchIllustrationImage(), variable, null, 'RoutineContent')) : executeQuery(taskCardQueries.fetchIllustrationImage(), variable, null, 'RoutineContent')
    })
  }
}

export const resetIllustrationImage = () => {
  return dispatch => {
    dispatch({
      type: RESET_ILLUSTRATIONS_IMAGES
    })
  }
}

export const updateSignoff = (signoffObject) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SIGNOFF_ARRAY,
      payload: signoffObject
    });
  }
}

export const updateSignoffValidations = (signoffValidations) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SIGNOFF_VALIDATIONS,
      payload: signoffValidations
    });
  }
}

export const addNavPointer = (navPointer) => {
  return dispatch => {
    dispatch({
      type: ADD_NAV_POINTER,
      payload: navPointer
    });
  }
}

export const setActiveNavItem = (activeItem) => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_NAV_ITEM,
      payload: activeItem
    });
  }
}


export const handleSignoffClick = (signoff, cardId, carddoctype, rootId, station, aircraftNumber, arrivalDate, profile, isPastOrFutureVisit) => {
  const signoffVariable = {
    "signOff": signoff
  }
  const routineContentVariable = {
    "routinesContent": {
      "assignedStation": station,
      "tailNumber": aircraftNumber,
      "flightNumber": signoff.taskCard.flightNumber,
      "forecastSerialNumber": signoff.taskCard.serialNumber,
      "workPackageSerialNumber": signoff.taskCard.workControlNumber,
      "jobNumber": signoff.taskCard.dash8,
      "cardNumber": signoff.taskCard.cardNumber,
      "employeeID": profile.userId,
      "carddoctype": carddoctype,
      "cardId": cardId,
      "rootId": rootId,
      "scheduledArrivalDate": arrivalDate,
      "isIDT": (profile.userActions.includes("[IsIDT, True]")),
      "isRII": (profile.userActions.includes("[IsRII, True]")),
      "isMVT": (profile.userActions.includes("[IsMvt, True]")),
      "originalEtaskJobRole": profile.originalEtaskRole,
      "jobRole": profile.jobRole,
      "isPastOrFutureVisit": isPastOrFutureVisit,
      "isArchivedCard": false,
      "isCascadingEnabled": signoff.taskCard.isCascadingEnabled,
      "masterId": signoff.taskCard.masterId,
      "departureDate": signoff.taskCard.departureDate,
    }
  }

  return (dispatch, getState) => {
    dispatch({
      type: SIGNOFF_BUTTON_CLICK,
      payload: trackPromise(executeQuery(taskCardQueries.updateSignOffInfo(), signoffVariable, getState(), 'SignOff'))
    }).then(resp => {
      if (resp.value.signOff && resp.value.signOff.status !== null) {
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: "Block(s) has been processed successfully!"
        });
        dispatch({
          type: FETCH_TASKCARD_DATA,
          payload: executeQuery(taskCardQueries.fetchTaskCardData(), routineContentVariable, getState(), 'RoutineContent')
        }).then(resp => {
          if (resp !== undefined && resp !== null && resp.routineRegister !== null) {
            dispatch({
              type: SET_LAST_REFRESHED,
              payload: Date.now()
            })
          }
        });
      }

    })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}

export const resetTaskcardFlags = () => {
  return dispatch => {
    dispatch({
      type: RESET_TASKCARD_DATA
    })
  }
}

export const resetSignoffErrorMessage = () => {
  return dispatch => {
    dispatch({
      type: RESET_SIGNOFF_ERROR_MESSAGE
    })
  }
}
export const resetSignoffUpdates = () => {
  return dispatch => {
    dispatch({
      type: RESET_SIGNOFF_ARRAY
    })
  }
}
export const toggleSignoffModal = (showSignoffModal) => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIGNOFF_MODAL,
      payload: showSignoffModal
    })
  }
}



export const fetchTaskCardPageData = (profile, queryStringValues, apiStation, isViewOnlyMode) => {
  return (dispatch, getState) => {
    const visitInfo = getState() && getState().visitsStore.visitInfo;
    dispatch({
      type: RESET_INDUCTED_AIRCRAFT_INFO
    });
    if (visitInfo) {
      const isPastOrFutureVisit = visitInfo && (visitInfo.actualInductionDate === null || visitInfo.isArchived);
      const variable = {
        "routinesContent": {
          "assignedStation": apiStation,
          "tailNumber": visitInfo.aircraftNumber,
          "flightNumber": "0000",
          "forecastSerialNumber": queryStringValues.serialno,
          "workPackageSerialNumber": queryStringValues.wcno,
          "jobNumber": queryStringValues.dash8,
          "cardNumber": queryStringValues.taskcard,
          "employeeID": profile.userId,
          "carddoctype": queryStringValues.carddoctype,
          "cardId": queryStringValues.cardid,
          "rootId": queryStringValues.rootid,
          "scheduledArrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
          "isIDT": (profile.userActions.includes("[IsIDT, True]")),
          "isRII": (profile.userActions.includes("[IsRII, True]")),
          "isMVT": (profile.userActions.includes("[IsMvt, True]")),
          "originalEtaskJobRole": profile.originalEtaskRole,
          "jobRole": profile.jobRole,
          "isPastOrFutureVisit": isPastOrFutureVisit,
          "isArchivedCard": false,
          "isViewOnlyMode": isViewOnlyMode,
          "masterId": queryStringValues.masterid,
          "isInducted": visitInfo && visitInfo.actualInductionDate !== null,
          "isCascadingEnabled": visitInfo.isCascadingEnabled,
          "showNonRoutines": visitInfo && visitInfo.showNonRoutines,
          "departureDate": visitInfo && visitInfo.plannedETRDate,
          "optimizedGroupID": queryStringValues.optgrpid,
          "optimizedDash8sList": queryStringValues.optd8list ? JSON.parse(queryStringValues.optd8list) : null,
          "isOptimized": queryStringValues.isoptimized,
          "dash1Status": queryStringValues.d1status
        }
      }
      dispatch({
        type: FETCH_TASKCARD_DATA,
        payload: executeQuery(taskCardQueries.fetchTaskCardData(), variable, getState(), 'RoutineContent')
      }).then(resp => {
        if (resp !== null && resp.routineContent !== null) {
          dispatch({
            type: SET_LAST_REFRESHED,
            payload: Date.now()
          })
        }
      })
        .catch(error => {
          dispatch({
            type: SET_GENERIC_ERROR,
            payload: error?.message
          })
        });
    }
  }
}
export const setCardSearchType = (type) => {

  return dispatch => {
    dispatch({
      type: CARD_SEARCH_TYPE,
      payload: type
    })
  }
}
export const toggleBulkSignoffModal = (showBulkSignoffModal) => {

  return dispatch => {
    dispatch({
      type: TOGGLE_BULK_SIGNOFF_MODAL,
      payload: showBulkSignoffModal
    })
  }
}


export const fetchLinkedLogPages = (tailNumber, station, serialNo, dash8, taskcard, scheduledDate) => {
  const variable = {
    "routineLinkedLP": {
      "tailNumber": tailNumber,
      "station": station,
      "serialNumber": serialNo,
      "scheduledArrivalDate": scheduledDate,
      "dash1": taskcard,
      "dash8": dash8

    }
  }
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_LINKED_LOGPAGES,
      payload: trackPromise(executeQuery(taskCardQueries.fetchLinkedLogPages(), variable, getState(), 'RoutineRegister'))
    }).then(resp => {
      if (resp?.value?.getRoutineLinkedLogPages?.linkedLogPages) {
        dispatch({
          type: SET_LAST_REFRESHED,
          payload: Date.now()
        })
      }
    })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}

export const resetLinkedLogPageData = () => {
  return dispatch => {
    dispatch({
      type: RESET_LINKED_LOGPAGE_DATA
    })
  }
}

export const getDash1Notes = (queryStringValues) => {
  return (dispatch, getState) => {
    const aircraftInfo = getState() && getState().visitsStore.aircraftInfo;
    const isOptimized = queryStringValues.isOptimized ? queryStringValues.isOptimized : queryStringValues.isoptimized === "true";
    if (aircraftInfo) {
      const variable = {
        "getd1Notes": {
          "tailNumber": aircraftInfo.aircraftNumber,
          "station": aircraftInfo.station,
          "flightNumber": aircraftInfo.inFlightNumber,
          "dash1": queryStringValues.taskcard ? queryStringValues.taskcard : queryStringValues.zonedash1 ? queryStringValues.zonedash1 : "",
          "dash8": queryStringValues.dash8 || "",
          "dash9": "",
          "scheduledArrivalDate": aircraftInfo.arrivalDate,
          "baseInductionDate": aircraftInfo.arrivalDate,
          "serialNumber": queryStringValues.serialno || "",
          "workControlNumber": queryStringValues.wcno || "",
          "nonRoutineID": queryStringValues.nonroutineid ? queryStringValues.nonroutineid : 0,
          "returnRefData": "1",
          "dash1Status": queryStringValues.dash1Status || (queryStringValues?.d1status ? queryStringValues?.d1status : null),
          "isOptimized": isOptimized,
          "isCascadingEnabled": aircraftInfo.isCascadingEnabled,
          "optimizedDash8s": isOptimized ?
            (queryStringValues.optimizedDash8s ||
              (queryStringValues?.optd8list ? JSON.parse(queryStringValues.optd8list).map(d8 => {
                return {
                  "forecastDescription": d8.forecastdescription,
                  "ncaPartNumber": d8.dash8,
                  "ncaSerialNumber": queryStringValues.aircraftnumber
                }
              }) : null)) : null
        }
      }
      dispatch({
        type: GET_DASH1_NOTES,
        payload: executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister')
      })
        .catch(error => {
          dispatch({
            type: SET_GENERIC_ERROR,
            payload: error?.message
          })
        });
    }
  }

}

export const saveDash1Notes = (isLine, queryStringValues, notes, updateNotesCount, optionalProps = {}) => {
  return (dispatch, getState) => {
    const aircraftInfo = getState() && getState().visitsStore.aircraftInfo;
    const profile = getState() && getState().profileStore.userProfile;
    const dash1Notevariable = {
      "d1Notes": {
        "tailNumber": aircraftInfo.aircraftNumber,
        "station": aircraftInfo.station,
        "flightNumber": aircraftInfo.inFlightNumber,
        "dash1": queryStringValues.taskcard ? queryStringValues.taskcard : queryStringValues.zonedash1,
        "dash8": queryStringValues.dash8,
        "dash9": null, //?
        "scheduledArrivalDate": aircraftInfo.arrivalDate,
        "serialNumber": queryStringValues.serialno,
        "workControlNumber": queryStringValues.wcno,
        "notes": notes,
        "employeeID": profile.userId,
        "employeeName": `${profile.firstName} ${profile.lastName}`,
        "isLineVisit": isLine,
        "baseInductionDate": aircraftInfo.arrivalDate,
        "workStop": queryStringValues.workStop,
        "workBinId": queryStringValues.workBinId,
        "actionTypeId": queryStringValues.actionTypeId,
        "nonRoutineID": queryStringValues.nonroutineid ? queryStringValues.nonroutineid : 0,
        "dash1Status": queryStringValues.dash1Status,
        "isOptimized": queryStringValues.isOptimized ? queryStringValues.isOptimized : false,
        "isCascadingEnabled": aircraftInfo.isCascadingEnabled,
        "optimizedDash8s": queryStringValues.isOptimized ? queryStringValues.optimizedDash8s : null,
      }
    }
    dispatch({
      type: SAVE_DASH1_NOTES,
      payload: trackPromise(executeQuery(taskCardQueries.saveDash1Notes(), dash1Notevariable, getState(), 'RoutineRegister'))
    })
      .then(resp => {
        if (resp.value.saveDash1Notes.workLoadID) {
          const variable = {
            "getd1Notes": {
              "tailNumber": aircraftInfo.aircraftNumber,
              "station": aircraftInfo.station,
              "flightNumber": aircraftInfo.inFlightNumber,
              "dash1": queryStringValues.taskcard ? queryStringValues.taskcard : queryStringValues.zonedash1,
              "dash8": queryStringValues.dash8,
              "dash9": "",
              "scheduledArrivalDate": aircraftInfo.arrivalDate,
              //"serialNumber": queryStringValues.serialno,
              "workControlNumber": queryStringValues.wcno,
              "baseInductionDate": aircraftInfo.arrivalDate,// for line not sure
              "serialNumber": queryStringValues.serialno || "",
              "workControlNumber": queryStringValues.wcno || "",
              "nonRoutineID": queryStringValues.nonroutineid ? queryStringValues.nonroutineid : 0,
              "returnRefData": "1",
              "dash1Status": queryStringValues.dash1Status,
              "isOptimized": queryStringValues.isOptimized ? queryStringValues.isOptimized : false,
              "isCascadingEnabled": aircraftInfo.isCascadingEnabled,
              "optimizedDash8s": queryStringValues.isOptimized ? queryStringValues.optimizedDash8s : null
            }
          }
          dispatch({
            type: SET_NOTIFICATION_MESSAGE,
            payload: "Note has been successfully saved"
          });
          switch (updateNotesCount) {
            //Added below code as per Shri's mention
            case 'local_summary_notes': {
              const { mxMasterId, originalCount, mxType } = optionalProps;
              if (mxMasterId) {
                dispatch({
                  type: SAVE_SHIFT_SUMMAR_NOTES_LOCALLY,
                  noteData: {
                    masterId: mxMasterId,
                    originalCount: originalCount,
                    mxType: mxType,
                    note: notes,
                    workStop: queryStringValues.workStop,
                    actionType: queryStringValues.abbreviatedActionType,
                    workbin: getState().workBinsStore?.workBins?.find(type => type.workBinId === queryStringValues.workBinId)
                  }
                })

                //Fetch the notes again to display in the modal.
                dispatch({
                  type: GET_DASH1_NOTES,
                  payload: trackPromise(executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister'))
                })
              }
            }
              break;
            case 'crew_workload_notes':
              dispatch({
                type: GET_DASH1_NOTES,
                payload: trackPromise(executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister'))
              }).then(getNoteResp => {
                const updatedNote = getNoteResp?.value?.getDash1Notes?.notes[0]?.notes;
                dispatch({
                  type: UPDATE_ASSIGN_CREW_NOTES_COUNT_LOCALLY,
                  payload: {
                    queryStringValues, taskTypes: getState().taskCardStore.dash1NoteTypes, isDup: (queryStringValues.dash1Status === "DEDUP" && queryStringValues.isOptimized && aircraftInfo.isCascadingEnabled), notes: {
                      note: updatedNote,
                      createdBy: profile.userId,
                      employeeName: `${profile.firstName} ${profile.lastName}`,
                    }
                  }
                })
              })
              break;
            case 'dash_card_content_notes':
              if (window.location.href.indexOf('MyShift') !== -1) {
                dispatch({
                  type: UPDATE_DASH1_NOTES_COUNT_MY_SHIFT,
                  payload: {
                    queryStringValues: queryStringValues,
                    lastNotes: notes,
                    workbin: getState().workBinsStore?.workBins?.find(type => type.workBinId === queryStringValues.workBinId),
                    isDup: (queryStringValues.dash1Status === "DEDUP" && queryStringValues.isOptimized) && aircraftInfo.isCascadingEnabled,
                    optimizedGroup: queryStringValues.optimizedGroup,
                    optimizedDash8s: queryStringValues.optimizedDash8s,
                    lastNoteDetail: {
                      createdBy: profile.userId,
                      employeeName: `${profile.firstName} ${profile.lastName}`,
                      createdDate: new Date()
                    }
                  }
                })
              }
              else {
                dispatch({
                  type: UPDATE_DASH1_NOTES_COUNT,
                  payload: {
                    dash8: queryStringValues.dash8,
                    dash1: queryStringValues.taskcard,
                    workStop: queryStringValues.workStop,
                    serialNumber: queryStringValues.serialno || "",
                    actionType: getState().taskCardStore.dash1NoteTypes.find(type => type.actionTypeId === queryStringValues.actionTypeId),
                    notes: notes,
                    workbin: getState().workBinsStore?.workBins?.find(type => type.workBinId === queryStringValues.workBinId),
                    isDup: (queryStringValues.dash1Status === "DEDUP" && queryStringValues.isOptimized && aircraftInfo.isCascadingEnabled),
                    optimizedGroup: queryStringValues.optimizedGroup,
                    optimizedDash8s: queryStringValues.optimizedDash8s,
                    lastNoteDetail: {
                      createdBy: profile.userId,
                      employeeName: `${profile.firstName} ${profile.lastName}`,
                      createdDate: new Date()
                    }
                  }
                })
              }
              dispatch({
                type: GET_DASH1_NOTES,
                payload: trackPromise(executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister'))
              })
              break;
            case 'nonRoutines':
              dispatch({
                type: UPDATE_NON_ROUTINES_NOTES_COUNT_LOCALLY,
                payload: {
                  ...queryStringValues, abbreviatedActionType: queryStringValues.abbreviatedActionType, notes: notes,
                  workbin: getState().workBinsStore?.workBins?.find(type => type.workBinId === queryStringValues.workBinId),
                  lastNoteDetail: {
                    createdBy: profile.userId,
                    employeeName: `${profile.firstName} ${profile.lastName}`,
                    createdDate: new Date()
                  }
                }
              });
              dispatch({
                type: GET_DASH1_NOTES,
                payload: trackPromise(executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister'))
              })
              break;
            case 'ms_taskcard':

              break;
            case 'ms_nonroutine':
              dispatch({
                type: UPDATE_NR_NOTES_COUNT_MY_SHIFT,
                payload: {
                  queryStringValues: queryStringValues,
                  lastNotes: notes,
                  workbin: getState().workBinsStore?.workBins?.find(type => type.workBinId === queryStringValues.workBinId),
                  lastNoteDetail: {
                    createdBy: profile.userId,
                    employeeName: `${profile.firstName} ${profile.lastName}`,
                    createdDate: new Date()
                  }
                }
              });
              dispatch({
                type: GET_DASH1_NOTES,
                payload: trackPromise(executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister'))
              })
              break;
            default:
              dispatch({
                type: GET_DASH1_NOTES,
                payload: trackPromise(executeQuery(taskCardQueries.getDash1Notes(), variable, getState(), 'RoutineRegister'))
              })
          }
        }
      })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }

}


export const resetDash1Notes = () => {
  return dispatch => dispatch({ type: RESET_DASH1_NOTES })
}

export const fetchFlifo = (profile, queryStringValues) => {
  return (dispatch, getState) => {
    const flifo = getState() && getState().registerStore?.filteredWorkload?.flifo
    if (flifo) {
      dispatch({
        type: FETCH_FLIFO,
        payload: flifo
      })
    }
    else {
      const variable = {
        "routinesWorkload": {
          "inFlightNumber": queryStringValues.inflightnumber,
          "arrivalDate": queryStringValues.arrivaldate,
          "departureDate": queryStringValues.departuredate
        }
      }
      dispatch({
        type: FETCH_FLIFO,
        payload: executeQuery(Queries.fetchFlifo(queryStringValues.aircraftnumber, profile.userId, queryStringValues.station, profile.jobRole, true, false, false, false), variable, getState(), 'RoutineRegister')
      }).then(resp => {
        if (resp !== null && resp.value.routineRegister !== null) {
          const aircraftInfo =
          {
            aircraftNumber: queryStringValues.aircraftnumber,
            arrivalDate: queryStringValues.arrivaldate,
            departureDate: queryStringValues.departuredate,
            acControlId: null,
            inFlightNumber: queryStringValues.inflightnumber,
            isPastOrFutureVisit: false,
            isArchived: false,
            isFuture: false,
            showNonRoutines: false,
            station: queryStringValues.station
          };
          dispatch({
            type: SET_AIRCRAFT_INFO,
            payload: aircraftInfo
          })
        }
        dispatch({
          type: SET_LAST_REFRESHED,
          payload: Date.now()
        })
      })
    }
  }
}

export const fetchLinkedEAs = (profile, visitInfo, queryStringValues, isLine, flifo) => {
  const variable = isLine ? {
    "taskCards": {
      "baseStation": queryStringValues.station,
      "jobRole": profile.jobRole,
      "isFlifo": true,
      "isHistoricalVisit": false,
      "isHvyChkPackage": false,
      "enableVS": false,
      "isLineAircraft": true,
      "tailnumber": queryStringValues.aircraftnumber,
      "station": queryStringValues.station,
      "appVersion": "",
      "employeeId": profile.userId,
      "carddoctype": queryStringValues.carddoctype,
      "rootId": queryStringValues.rootid,
      "getEtaskWorkload": {
        "inFlightNumber": queryStringValues.inflightnumber,
        "flightNumber": flifo.flightNumber,
        "outFlightNumber": queryStringValues.inflightnumber,
        "nextFlightNumber": flifo.nextFlightNumber,
        "arrivalDate": queryStringValues.bestarrivaldate,
        "departureDate": queryStringValues.departuredate,
        "estimatedDepartureDate": queryStringValues.departuredate,
        "estimatedArrivalDate": queryStringValues.bestarrivaldate,
        "thruFlight": flifo.isRon, //?
        "isEtops": flifo.isEtops, //?
        "isExcludeADCard": true,
        "maintainenceItem": queryStringValues.dash8,
        "serialNumber": queryStringValues.serialno,
        "workControlNumber": queryStringValues.wcno,
        "workItemDescription": queryStringValues.carddescription,
        "deferralPositionCode": queryStringValues.positioncode,
        "workItemStatus": "", //?
        "isDash8Completed": false //?
      }
    }
  }
    :
    {
      "taskCards": {
        "baseStation": visitInfo.station,
        "jobRole": profile.jobRole,
        "isFlifo": false,
        "isHistoricalVisit": visitInfo.isArchived,
        "isHvyChkPackage": visitInfo.enableZonalCard,
        "enableVS": visitInfo.enableVS,
        "isLineAircraft": false,
        "tailnumber": visitInfo.aircraftNumber,
        "station": visitInfo.station,
        "appVersion": "",
        "employeeId": profile.userId,
        "carddoctype": queryStringValues.carddoctype,
        "rootId": queryStringValues.rootid,
        "getEtaskWorkload": {
          "inFlightNumber": "0000",
          "flightNumber": "0000",
          "outFlightNumber": "0000",
          "nextFlightNumber": "0000",
          "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
          "departureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate,
          "estimatedDepartureDate": visitInfo.actualETRDate || visitInfo.plannedETRDate,
          "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
          "thruFlight": false, //?
          "isEtops": false, //?
          "isExcludeADCard": true,
          "maintainenceItem": queryStringValues.dash8,
          "serialNumber": queryStringValues.serialno,
          "workControlNumber": queryStringValues.wcno,
          "workItemDescription": queryStringValues.carddescription,
          "deferralPositionCode": queryStringValues.positioncode,
          "workItemStatus": "", //?
          "isDash8Completed": false //?
        }
      }
    }
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_LINKED_EA,
      payload: executeQuery(taskCardQueries.getTaskCardsByMaintainenceItem(), variable, getState(), 'RoutineContent')
    })
      .then(resp => {
        if (resp?.value?.getTaskCardsByMaintainenceItem?.etaskCards) {
          dispatch({
            type: SET_LAST_REFRESHED,
            payload: Date.now()
          })
        }
      })
      .catch(error => {
        dispatch({
          type: SET_GENERIC_ERROR,
          payload: error?.message
        })
      });
  }
}