export const fetchProfileQuery = () => {
  return `{          
        profile {
          userId
          firstName
          lastName
          station
          isReleaseNotesViewed
        }          
      }`
}

export const fetchExtendedProfile = (userId, station) => {
  return `query Profile {
        getProfile(userId: "`+ userId + `") {
          userId
          firstName
          lastName
          station
          jobRole
          originalEtaskRole
          isBaseOverride
          userActions
          isBaseFlow
          isProductionController
          isVendorStation
          lTerm
          isReleaseNotesViewed
        }
      }`
}

export const saveFeedback = () => {
  return `mutation Application($feedbackRequest: FeedbackInputType, $userInfo: UserInformationInputType) {
    submitFeedbackWithAttachments(
      feedbackRequest: $feedbackRequest
      userInfo: $userInfo
    ) {
      feedbackId
      serviceNowHRCaseNumber
      errors
    }
  }`
}

export const saveReleaseNotesFlag = () => {
  return `mutation Visit($re:ViewedReleaseNotesRequestType) {
    viewedReleaseNotes(viewedreleasenotes:$re){
    isSuccess
    }
  }`
}

export const fetchReleaseNotes = () => {
  return `query Visit {
        getReleaseNotesDetails {
          releaseNotesAttributes{
         releaseId
         releaseVersion
        releaseDate
      releaseNotes {
         title
         description
      }
        }
        }
      }`
}

export const fetchReleaseVersion = () => {
  return `query Visit {
    getReleaseVersion {  
         releaseVersion            
      }    
    }`
}