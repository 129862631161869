import {
    SEARCH_WORKLOAD, SEARCH_WORKLOAD_ENHANCED, SET_LAST_REFRESHED, SET_GENERIC_ERROR, GET_BASE_CREW_WORKLOAD,
    UNASSIGN_CREW, SET_NOTIFICATION_MESSAGE, GET_AVAIALABLE_CREW, GET_GROUPS_AND_WORKAREA, GET_GROUPS_AND_WORKAREA_WITH_COUNTS,
    GET_PROGRESS_FOR_TASKCARDS, SET_SELECTED_WORKLOAD, SET_SELECTED_CREWS, SET_SELECTED_ASSIGNED_WORKLOAD,
    CURRENT_CREW_DATE, SET_SELECTED_WORKLOAD_FOR_WORKBINS, RESET_SEARCH_WORKLOAD, SET_IS_ITEM_UNCHECKED,
    UNASSIGN_ASSIGN_WORKLOAD, CREW_WORK_LOAD_SEARCH, UNASSIGN_ASSIGN_WORKLOAD_LOCAL_UPDATE, SAVE_LAST_BLOCK_NUMBER,
    SET_CREWS_FOR_ASSIGN_UNASSIGN_MODAL
} from "./actionTypes";
import * as Queries from '../queries/AssignCrewQueries';
import { executeQuery } from '../helper';
import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';

export const searchWorkload = (searchRequest, id, isTemplate) => {

    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        let variable = {};
        if (!isTemplate) {
            variable = {
                "searchWorkload": {
                    "visitId": visitInfo.visitId,
                    "isCalledByWeb": true,
                    "taskCardNumber": searchRequest.taskcardNumber.value,
                    "nRNumber": searchRequest.nRNumber.value,
                    "workArea": searchRequest.workAreaId.value,
                    "workBinId": searchRequest.workBinId.value,
                    "taskCardTypeId": searchRequest.taskcardTypeId.value,
                    "mileStone": searchRequest.mxVisitGroupId.value,
                    "crew": searchRequest.crewConfigId.value,
                    "shiftNumber": searchRequest.shiftNumber.value,
                    "taskCardDescription": searchRequest.description.value,
                    "tailNumber": visitInfo.aircraftNumber,
                    "inFlightNumber": "0000",
                    "station": visitInfo.station,
                    "packageType": visitInfo.packageType,
                    "userId": profile.userId,
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.plannedETRDate,
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "skillId": searchRequest.skillId.value,
                    "workloadForWorkBins": searchRequest.workloadForWorkBins,
                    "zoneTypeID": searchRequest.zoneTypeId.value
                }
            };
        }
        else {
            variable = {
                "searchWorkload": {
                    "templateId": id,
                    "isCalledByWeb": true,
                    "taskCardNumber": searchRequest.taskcardNumber.value,
                    "nRNumber": "",
                    "workArea": searchRequest.workAreaId.value,
                    "workBinId": searchRequest.workBinId.value,
                    "taskCardTypeId": searchRequest.taskcardTypeId.value,
                    "mileStone": searchRequest.mxVisitGroupId.value,
                    "crew": "",
                    "shiftNumber": searchRequest.shiftNumber.value,
                    "taskCardDescription": searchRequest.description.value,
                    "skillId": searchRequest.skillId.value,
                    "workloadForWorkBins": true,
                    "zoneTypeID": searchRequest.zoneTypeId.value
                }

            }
        }
        dispatch({
            type: CREW_WORK_LOAD_SEARCH,
            payload: searchRequest
        })
        dispatch({
            type: SEARCH_WORKLOAD,
            payload: executeQuery(isTemplate ? Queries.searchTemplateWorkload() : Queries.searchWorkload(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                if (!isTemplate && resp !== null && resp.value.searchWorkload !== null) {
                    const progressVariable = {
                        "progress": {
                            "assignedStation": visitInfo.station,
                            "cardNumbers": resp.value.searchWorkload?.mxItems?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC")?.map(wl => ({
                                cardNumber: wl.mxType === "D1" ? wl.cardNumber : wl.zoneCardNumber,
                                workControlNumber: wl.workControlNumber,
                                serialNumber: wl.serialNumber,
                                jobNumber: wl.dash8,
                               //adding masterid to get the correct counts for progress, rumiana asked me to add the masterid we are getting from above response
                                masterId: wl.masterId,
                                zoneId: wl.zoneId
                            })),
                            "tailNumber": visitInfo.aircraftNumber,
                            "flightNumber": "0000",
                            "scheduledArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate
                        }
                    };
                    dispatch({
                        type: GET_PROGRESS_FOR_TASKCARDS,
                        payload: executeQuery(Queries.getProgressForTaskCards(), progressVariable, getState(), 'TemplateManagement')
                    })
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const searchWorkloadWithMultiSelect = (searchRequest, id, isTemplate) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        dispatch({
            type: SET_SELECTED_WORKLOAD,
            payload: []
        })
        if(isTemplate){
            dispatch(searchTemplateWorkloadWithMultiSelect(searchRequest, id));
            return false
        }
        let variable = {};
        if (!isTemplate && visitInfo) {
            variable = {
                "searchWorkload": {
                    "visitId": visitInfo.visitId,
                    "isCalledByWeb": true,
                    "taskCardNumber": searchRequest.taskcardNumber.value,
                    "nRNumber": searchRequest.nRNumber.value,
                    "workArea": searchRequest.workAreaId.value,
                    "workBinIds": searchRequest.workBinIds.value.map(({ value }) => value),
                    "taskCardTypeIds": searchRequest.taskcardTypeIds.value.map(({ value }) => value),
                    // "mileStoneIds": searchRequest.mxVisitGroupId.value,
                    "mileStoneIds": searchRequest.mileStoneIds.value.map(({ value }) => value),
                    "crews": searchRequest.crews.value.map(({ value }) => value),
                    "shiftNumbers": searchRequest.shiftNumbers.value.map(({ value }) => value),
                    "taskCardDescription": searchRequest.description.value,
                    "tailNumber": visitInfo.aircraftNumber,
                    "inFlightNumber": "0000",
                    "station": visitInfo.station,
                    "packageType": visitInfo.packageType,
                    "userId": profile.userId,
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.plannedETRDate,
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "skillIds": searchRequest.skillIds.value.map(({ value }) => value),
                    "workloadForWorkBins": searchRequest.workloadForWorkBins,
                    "zoneTypeIDs": searchRequest.zoneTypeIds.value.map(({ value }) => value),
                    "filterBy": searchRequest.filterBy?.value ? searchRequest.filterBy?.value : null,
                    "enableVS": visitInfo?.enableVS,
                    "nonRoutineStatus": searchRequest.nonRoutineStatus?.value ? searchRequest.nonRoutineStatus?.value : null,
                    "returnCompleted": searchRequest.workloadStatus?.value?.toLowerCase() == 'completed',
                    "includeCompleted": searchRequest.workloadStatus?.value?.toLowerCase() == 'all'
                    //...(searchRequest.hasOwnProperty('returnCompleted') ? {returnCompleted: searchRequest.returnCompleted}:{})
                }
            };
    }
        dispatch({
            type: CREW_WORK_LOAD_SEARCH,
            payload: searchRequest
        })
        dispatch({
            type: SEARCH_WORKLOAD,
            payload: executeQuery(isTemplate ? Queries.searchTemplateWorkload() : Queries.searchWorkloadWithMultiSelect(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                const mxItems = resp !== null && resp.value.searchWorkloadWithMultiSelect !== null && resp.value.searchWorkloadWithMultiSelect?.mxItems?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC");
                if (!isTemplate && mxItems?.length > 0) {
                    const progressVariable = {
                        "progress": {
                            "assignedStation": visitInfo.station,
                            "cardNumbers": resp.value.searchWorkloadWithMultiSelect?.mxItems?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC")?.map(wl => ({
                                cardNumber: wl.mxType === "D1" ? wl.cardNumber : wl.zoneCardNumber,
                                workControlNumber: wl.workControlNumber,
                                serialNumber: wl.serialNumber,
                                jobNumber: wl.dash8,
                                //adding masterid to get the correct counts for progress, rumiana asked me to add the masterid we are getting from above response
                                masterId: wl.masterId,
                                zoneId: wl.zoneId
                            })),
                            "tailNumber": visitInfo.aircraftNumber,
                            "flightNumber": "0000",
                            "scheduledArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate
                        }
                    };
                    dispatch({
                        type: GET_PROGRESS_FOR_TASKCARDS,
                        payload: executeQuery(Queries.getProgressForTaskCards(), progressVariable, getState(), 'TemplateManagement')
                    })
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
                else {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error.message
                })
            });
    }
}

export const searchWorkloadWithMultiSelectEnhanced = (searchRequest, id, isTemplate) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        dispatch({
            type: SET_SELECTED_WORKLOAD,
            payload: []
        })
        if(isTemplate){
            dispatch(searchTemplateWorkloadWithMultiSelect(searchRequest, id));
            return false
        }
        let variable = {};
        if (!isTemplate && visitInfo) {
            variable = {
                "searchWorkload": {
                    "visitId": visitInfo.visitId,
                    "isCalledByWeb": true,
                    "taskCardNumber": searchRequest.taskcardNumber.value,
                    "nRNumber": searchRequest.nRNumber.value,
                    "workArea": searchRequest.workAreaId.value,
                    "workBinIds": searchRequest.workBinIds.value.map(({ value }) => value),
                    "taskCardTypeIds": searchRequest.taskcardTypeIds.value.map(({ value }) => value),
                    // "mileStoneIds": searchRequest.mxVisitGroupId.value,
                    "mileStoneIds": searchRequest.mileStoneIds.value.map(({ value }) => value),
                    "crews": searchRequest.crews.value.map(({ value }) => value),
                    "shiftNumbers": searchRequest.shiftNumbers.value.map(({ value }) => value),
                    "taskCardDescription": searchRequest.description.value,
                    "tailNumber": visitInfo.aircraftNumber,
                    "inFlightNumber": "0000",
                    "station": visitInfo.station,
                    "packageType": visitInfo.packageType,
                    "userId": profile.userId,
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.plannedETRDate,
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "skillIds": searchRequest.skillIds.value.map(({ value }) => value),
                    "workloadForWorkBins": searchRequest.workloadForWorkBins,
                    "zoneTypeIDs": searchRequest.zoneTypeIds.value.map(({ value }) => value),
                    "filterBy": searchRequest.filterBy?.value ? searchRequest.filterBy?.value : null,
                    "enableVS": visitInfo?.enableVS,
                    "nonRoutineStatus": searchRequest.nonRoutineStatus?.value ? searchRequest.nonRoutineStatus?.value : null,
                    "returnCompleted": searchRequest.workloadStatus?.value?.toLowerCase() == 'completed',
                    "includeCompleted": searchRequest.workloadStatus?.value?.toLowerCase() == 'all',
                    "returnDependencies": true,
                    "workItemAssignmentStatus": searchRequest?.assignment?.value ? searchRequest?.assignment?.value : null,
                    "isHistoricalVisit": visitInfo?.isArchived,
                    "originatingDocument": searchRequest?.originatingDocument?.value ? searchRequest?.originatingDocument?.value : ""
                    //...(searchRequest.hasOwnProperty('returnCompleted') ? {returnCompleted: searchRequest.returnCompleted}:{})
                }
            };
    }
        dispatch({
            type: CREW_WORK_LOAD_SEARCH,
            payload: searchRequest
        })
        dispatch({
            type: SEARCH_WORKLOAD_ENHANCED,
            payload: executeQuery(isTemplate ? Queries.searchTemplateWorkload() : Queries.searchWorkloadWithMultiSelectEnhanced(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                const mxItems = resp !== null && resp.value.searchWorkloadWithMultiSelect_Enhanced !== null && resp.value.searchWorkloadWithMultiSelect_Enhanced?.mxItems?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC");
                if (!isTemplate && mxItems?.length > 0) {
                    const progressVariable = {
                        "progress": {
                            "assignedStation": visitInfo.station,
                            "cardNumbers": resp.value.searchWorkloadWithMultiSelect_Enhanced?.mxItems?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC")?.map(wl => ({
                                cardNumber: wl.mxType === "D1" ? wl.cardNumber : wl.zoneCardNumber,
                                workControlNumber: wl.workControlNumber,
                                serialNumber: wl.serialNumber,
                                jobNumber: wl.dash8,
                                //adding masterid to get the correct counts for progress, rumiana asked me to add the masterid we are getting from above response
                                masterId: wl.masterId,
                                zoneId: wl.zoneId
                            })),
                            "tailNumber": visitInfo.aircraftNumber,
                            "flightNumber": "0000",
                            "scheduledArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate
                        }
                    };
                    dispatch({
                        type: GET_PROGRESS_FOR_TASKCARDS,
                        payload: executeQuery(Queries.getProgressForTaskCards(), progressVariable, getState(), 'TemplateManagement')
                    })
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
                else {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error.message
                })
            });
    }
}

export const searchTemplateWorkloadWithMultiSelect = (searchRequest, id) => {
    return (dispatch, getState) => {
        let variable = {}; 
        variable = {
            "searchTemplateWorkload": {
                "templateId": id,
                    "taskCardId": searchRequest.taskcardNumber.value,
                    "taskcardDescription": searchRequest.description.value,     
                    "milestoneIds": searchRequest.mileStoneIds.value.map(({ value }) => value),
                    "workbinIds": searchRequest.workBinIds.value.map(({ value }) => value),
                    "zoneTypeIds": searchRequest.zoneTypeIds.value.map(({ value }) => value),
                    "taskCardTypeIds": searchRequest.taskcardTypeIds.value.map(({ value }) => value),
                    "skillIds": searchRequest.skillIds.value.map(({ value }) => value),
                    "shiftNumbers": searchRequest.shiftNumbers.value.map(({ value }) => value),
                    "returnDependencies": searchRequest.returnDependencies,
                }
        }
        dispatch({
            type: CREW_WORK_LOAD_SEARCH,
            payload: searchRequest
        })
        dispatch({
            type: SEARCH_WORKLOAD,
            payload: executeQuery(Queries.searchTemplateWorkloadWithMultiSelect(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                //const mxItems = resp !== null && resp.value.searchTemplateWorkloadWithMultiSelect !== null && resp.value.searchTemplateWorkloadWithMultiSelect?.mxItems?.filter(mi => mi.mxType === "D1" || mi.mxType === "ZC");
                
                dispatch({
                    type: SET_LAST_REFRESHED,
                    payload: Date.now()
                })
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error.message
                })
            });
    }
}

// export const getBaseCrewWorkload = (crewConfigId, visitId) => {
//     return (dispatch, getState) => {
//         const visitInfo = getState() && getState().visitsStore.visitInfo;
//         const profile = getState() && getState().profileStore.userProfile;
//         const variable = {
//             "baseCrew": {
//                 "crewConfigId": crewConfigId,
//                 "visitId": visitId,
//                 "tailNumber": visitInfo.aircraftNumber,
//                 "inFlightNumber": "0000",
//                 "station": visitInfo.station,
//                 "packageType": visitInfo.packageType,
//                 "userId": profile.userId,
//                 "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
//                 "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
//                 "departureDate": visitInfo.plannedETRDate,
//                 "isHvyChkPackage": visitInfo.enableZonalCard
//             }
//         };
//         dispatch({
//             type: GET_BASE_CREW_WORKLOAD,
//             payload: executeQuery(Queries.getBaseCrewWorkload(), variable, getState(), 'TemplateManagement')
//         })
//             .then(resp => {
//                 if (resp !== null && resp.value.getBaseCrewWorkload.assignedWorkload !== null) {
//                     dispatch({
//                         type: SET_LAST_REFRESHED,
//                         payload: Date.now()
//                     })
//                 }
//             })
//             .catch(error => {
//                 dispatch({
//                     type: SET_GENERIC_ERROR,
//                     payload: error?.message
//                 })
//             });
//     }
// }

export const getBaseCrewWorkload = (crewConfigId, visitId, isTemplate=false) => {
    return (dispatch, getState) => {
        const profile = getState() && getState().profileStore.userProfile;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        let variable = {};
        if (!isTemplate && visitInfo) {
            variable = {
                "searchWorkload": {
                    "visitId": visitId,
                    "isCalledByWeb": true,
                    "taskCardNumber": "",
                    "nRNumber": "",
                    "workArea": "",
                    "workBinIds": [],
                    "taskCardTypeIds": [],
                    "mileStoneIds": [],
                    "crews": [crewConfigId],
                    "shiftNumbers": [],
                    "taskCardDescription": "",
                    "tailNumber": visitInfo.aircraftNumber,
                    "inFlightNumber": "0000",
                    "station": visitInfo.station,
                    "packageType": visitInfo.packageType,
                    "userId": profile.userId,
                    "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                    "departureDate": visitInfo.plannedETRDate,
                    "isHvyChkPackage": visitInfo.enableZonalCard,
                    "skillIds": [],
                    "workloadForWorkBins": false,
                    "zoneTypeIDs": []
                }
            };
        };
        dispatch({
            type: GET_BASE_CREW_WORKLOAD,
            payload: executeQuery(Queries.searchWorkloadWithMultiSelect(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                if (resp !== null && !!resp.value.searchWorkloadWithMultiSelect.mxItems.length) {
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const handleUnassignClick = (crew) => {
    return (dispatch, getState) => {
        const selectedAssignedWorkload = getState() && getState().visitScheduleStore.selectedAssignedWorkload;
        const profile = getState() && getState().profileStore.userProfile;
        const visitShifts = getState() && getState().visitScheduleStore.visitShifts;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        let unAssignRequest = {
            "crew": {
                "assignCrewDetails": [
                    {
                        "crewConfigId": crew?.crewConfigId,
                        "crewName": crew?.crewName,
                        "mxMasterIds": selectedAssignedWorkload,
                        "shiftName": crew?.shiftName
                    }
                ],
                "modifiedBy": profile.userId,
                "assign": false,
                "date": moment(moment.now()).format("YYYY-MM-DDTHH:mm:ss"),
                "scheduledArrivalDate": visitInfo?.actualInductionDate,
                "station": visitInfo?.station,
                "shiftCount": visitShifts?.length
            }
        }

        dispatch({
            type: UNASSIGN_CREW,
            payload: trackPromise(executeQuery(Queries.assignOrUnassignCrew(), unAssignRequest, getState(), 'TemplateManagement'))
        }).then(resp => {
            dispatch({
                type: SET_NOTIFICATION_MESSAGE,
                payload: resp?.value?.assignOrUnassignCrew && resp?.value?.assignOrUnassignCrew.isSuccess === true ? "Selected workload has been un-assigned successfully!" : "Error occured while un-assigning workload"
            });
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const getAvailableCrew = (request, addDays) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
            "crews": {
                "station": visitInfo.station,
                "baseStation": visitInfo.station,
                "date": moment(moment.now()).add(addDays, 'days').format("YYYY-MM-DDTHH:mm:ss"), //moment(date, "YYYY-MM-DDTHH:mm:ss").add(addDays, 'days'),   
                "employeeID": profile.userId,
                "jobRole": profile.jobRole,
                "tailNumber": visitInfo.aircraftNumber,
                "flightNumber": "0000",
                "scheduledArrivalDate": visitInfo.actualInductionDate !== "" && visitInfo.actualInductionDate !== null ? visitInfo.actualInductionDate : visitInfo.plannedInductionDate,
            }
        };

        dispatch({
            type: GET_AVAIALABLE_CREW,
            payload: executeQuery(Queries.getAvailableCrew(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                if (resp !== null && resp.value.availableCrews?.shiftCrews !== null) {
                    dispatch({
                        type: SET_CREWS_FOR_ASSIGN_UNASSIGN_MODAL,
                        payload: resp.value.availableCrews
                    })
                    dispatch({
                        type: SET_LAST_REFRESHED,
                        payload: Date.now()
                    })
                }
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const getGroupsAndWorkAreas = (visitId) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const variable = {
            "data": {
                "visitId": visitId,
                "returnOnlyItemsWithWorkLoad": true,
                "isHistoricalVisit": visitInfo?.isArchived
            }
        }
        dispatch({
            type: GET_GROUPS_AND_WORKAREA,
            payload: executeQuery(Queries.getGroupsAndWorkAreas(), variable, getState(), 'TemplateManagement')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const getGroupsAndWorkAreasWithCounts = (visitId) => {
    return (dispatch, getState) => {
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const profile = getState() && getState().profileStore.userProfile;
        const variable = {
            "data": {
                "visitId": visitId, 
                "isCalledByWeb": true,
                "taskCardNumber": "",
                "nRNumber": "",
                "workArea": "",
                "workBinIds": [],
                "taskCardTypeIds": [],
                "mileStoneIds": [],
                "crews": [],
                "shiftNumbers": [],
                "taskCardDescription": "",
                "tailNumber": visitInfo.aircraftNumber,
                "inFlightNumber": "0000",
                "station": visitInfo.station,
                "packageType": visitInfo.packageType,
                "userId": profile.userId,
                "arrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                "estimatedArrivalDate": visitInfo.actualInductionDate || visitInfo.plannedInductionDate,
                "departureDate": visitInfo.plannedETRDate,
                "isHvyChkPackage": visitInfo.enableZonalCard,
                "skillIds": [],
                "workloadForWorkBins": false,
                "zoneTypeIDs": [],
                "filterBy": null,
                "enableVS": visitInfo?.enableVS,
                "nonRoutineStatus": null,
                "returnCompleted": false,
                "includeCompleted": false,
                "isHistoricalVisit": visitInfo?.isArchived
            }    
        }
        dispatch({
            type: GET_GROUPS_AND_WORKAREA_WITH_COUNTS,
            payload: executeQuery(Queries.getGroupsAndWorkAreasWithCounts(), variable, getState(), 'VisitSchedule')
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setSelectedWorkload = (workload, isChecked, isAllSelected) => {
    return (dispatch, getState) => {
        const selectedWorkload = getState() && getState().visitScheduleStore.selectedWorkload;
        let updatedSelections = []
        if (isChecked) {
            updatedSelections = isAllSelected ? workload : [...selectedWorkload, workload];
        }
        else {
            updatedSelections = isAllSelected ? [] : selectedWorkload.filter(wl => (wl.mxMasterId !== workload.mxMasterId || wl.mxTemplateMasterId !== workload.mxTemplateMasterId))
        }

        dispatch({
            type: SET_SELECTED_WORKLOAD,
            payload: updatedSelections
        })
    }
}

export const setSelectedCrew = (crew) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_CREWS,
            payload: crew
        })
    }
}

export const setSelectedWorklodForBins = (workload) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_WORKLOAD_FOR_WORKBINS,
            payload: workload
        })
    }
}

export const handleAssignUnAssignCrew = (isAssign, _selectedCrews = [], _selectedWorkload = []) => {
    return (dispatch, getState) => {
        const selectedCrews = (_selectedCrews && _selectedCrews.length) ? _selectedCrews : getState() && getState().visitScheduleStore.selectedCrews;
        const selectedWorkload = (_selectedWorkload && _selectedWorkload.length) ? _selectedWorkload : getState() && getState().visitScheduleStore.selectedWorkload;
        const visitShifts = getState() && getState().visitScheduleStore.visitShifts;
        const availableCrew = getState() && getState().visitScheduleStore.availableCrew;
        const visitInfo = getState() && getState().visitsStore.visitInfo;
        const profile = getState() && getState().profileStore.userProfile;
        let assignRequest = {
            "crew": {
                "assignCrewDetails": selectedCrews && selectedCrews.map(sc => (
                    {
                        "crewConfigId": sc,
                        "crewName": availableCrew.find(ac => ac.crewConfigId === sc)?.crewName,
                        "mxMasterIds": _selectedWorkload.length ? _selectedWorkload : selectedWorkload && selectedWorkload.map(sw => sw.mxMasterId),
                        "shiftName": availableCrew.find(ac => ac.crewConfigId === sc)?.shiftName
                    }
                )
                ),
                "modifiedBy": profile.userId,
                "assign": isAssign,
                "date": moment(moment.now()).format("YYYY-MM-DDTHH:mm:ss"),
                "scheduledArrivalDate": visitInfo?.actualInductionDate,
                "station": visitInfo?.station,
                "shiftCount": visitShifts?.length
            }
        }

        dispatch({
            type: UNASSIGN_CREW,
            payload: trackPromise(executeQuery(Queries.assignOrUnassignCrew(), assignRequest, getState(), 'TemplateManagement'))
        }).then(resp => {
            if ((_selectedCrews && _selectedCrews.length) && (_selectedWorkload && _selectedWorkload.length)) {
                dispatch({ type: UNASSIGN_ASSIGN_WORKLOAD, payload: _selectedWorkload })
            }
            if ((selectedCrews && selectedCrews.length) && (selectedWorkload && selectedWorkload.length) && _selectedCrews?.length === 0 && _selectedWorkload?.length === 0) {
                dispatch({ type: UNASSIGN_ASSIGN_WORKLOAD_LOCAL_UPDATE, payload: { workload: selectedWorkload.map(sw => sw.mxMasterId), crews: selectedCrews, isAssign: isAssign } })
            }
            dispatch({
                type: SET_NOTIFICATION_MESSAGE,
                payload: resp?.value?.assignOrUnassignCrew && resp?.value?.assignOrUnassignCrew.isSuccess === true ? (isAssign ? "Selected crews has been assigned successfully!" : "Selected Workload(s) has been unassigned successfully!") : (isAssign ? "Error occured while assigning crews" : "Error occured while unassigning Workload(s)")
            });
        })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}

export const setSelectedAssignedWorkload = (panelAssignedWorkload) => {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_ASSIGNED_WORKLOAD,
            payload: panelAssignedWorkload
        })
    }
}

export const checkCurrentCrewDate = (isCurrentDay) => {
    return dispatch => {
        dispatch({
            type: CURRENT_CREW_DATE,
            payload: isCurrentDay
        })
    }
}

export const resetSearchWorkload = () => {
    return dispatch => {
        dispatch({
            type: RESET_SEARCH_WORKLOAD
        })
    }
}

export const setIsItemUnchecked = (val) => {
    return dispatch => {
        dispatch({
            type: SET_IS_ITEM_UNCHECKED,
            payload: val
        })
    }
}

export const getLastSignedBlockNumber = (blockRequest) => {
    return (dispatch, getState) => {
        const variable = { blockRequest };
        return dispatch({
            type: SAVE_LAST_BLOCK_NUMBER,
            payload: executeQuery(Queries.getLastSignedBlockNumber(), variable, getState(), 'TemplateManagement')
        })
            .then(resp => {
                return Promise.resolve(resp?.value?.getLastSignedBlockNumber?.blockNumber);
            })
            .catch(error => {
                dispatch({
                    type: SET_GENERIC_ERROR,
                    payload: error?.message
                })
            });
    }
}
